import React, { useContext, useEffect, useState, useRef } from "react";
import { List } from "components/core";
import ListItem from "./ListItemHistorical";
import { AppDataContext } from "context/AppDataProvider";
import { localDate } from "helpers/dateUtilities";
import { isNull, isNullEmptyOrWhitespace } from "helpers/stringUtilities";

export default function Historical({
  farmId,
  houseId,
  menu,
  forms = [],
  formIds,
}) {
  const { fetchFormValues } = useContext(AppDataContext);

  const [formValues, setFormValues] = useState(undefined);
  const [loaded, setLoaded] = useState(false);
  // const [formsMap, setFormsMap] = useState(undefined);

  const abortControllerRef = useRef(undefined);
  const dateToday = localDate();

  //#region Callbacks

  function isChildForm(formValueRecord) {
    return (
      !isNullEmptyOrWhitespace(formValueRecord.ParentPWAID) ||
      !isNullEmptyOrWhitespace(formValueRecord.ParentID)
    );
  }

  //#endregion

  //#region Side-effects

  /**
   * Mount/Unmount
   */
  useEffect(
    () => {
      abortControllerRef.current = new AbortController(); // Should always come first

      return () => {
        abortControllerRef.current.abort();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // /**
  //  * Set forms map
  //  */
  // useDeepCompareEffectNoCheck(() => {
  //   if (!forms) return;

  //   const _newFormsMap = new Map();
  //   for (const form of forms) {
  //     _newFormsMap.set(form.FormName.toLowerCase(), form);
  //   }

  //   setFormsMap(_newFormsMap);
  // }, [forms]);

  /**
   * Set form values
   */
  useEffect(() => {
    if (!farmId || !houseId || !fetchFormValues || !formIds.length) return;

    function populateFormValues() {
      const { signal } = abortControllerRef.current;

      fetchFormValues(farmId, houseId, formIds, signal)
        .then((result) => {
          if (signal.aborted || result === undefined) return; // Aborted or fetchFormValues farms not yet loaded
  
          // Result could be an array of undefined values [undefined, undefined, undefined]
          const _formValues = result
            ?.filter((r) => r !== undefined)
            // Flatten array of responses in single response
            .flat()
            // Sort chronologically after flattening array
            .sort(
              (a, b) =>
                b._DateApplies?.normalised.getTime() -
                  a._DateApplies?.normalised.getTime() ||
                b._LastModified?.normalised.getTime() -
                  a._LastModified?.normalised.getTime()
            );

          setFormValues(_formValues ?? []);
        })
        .catch((error) => {
          if (signal.aborted) return;

          console.error(error.message);
        });
    }

    populateFormValues();
  }, [farmId, houseId, fetchFormValues, formIds]);

  /**
   * Set loaded
   */
  useEffect(() => {
    if (formValues === undefined) return;

    setLoaded((currentState) => (currentState === false ? true : currentState));
  }, [formValues]);

  //#endregion

  //#region Event handlers

  //#endregion

  return (
    <>
      <List theme="striped" size="small" loaded={loaded}>
        {formValues
          ?.filter((fv) => !isChildForm(fv))
          ?.map((fv) => {
            function getCurrentRecordPWAID(formValues) {
              return formValues.PenValues?.find(
                (pv) => pv.Pen.toString() === "1"
              )?.Values.find((v) => v.Ref.toLowerCase() === "pwaid")?.Value;
            }

            function isChildOfForm(
              parentFormValueRecord,
              childFormValueRecord
            ) {
              return (
                childFormValueRecord.ParentPWAID ===
                getCurrentRecordPWAID(parentFormValueRecord)
              );
            }

            return (
              <ListItem
                key={`${fv.FormName}-${fv.ID ?? getCurrentRecordPWAID(fv)}`}
                farmId={farmId}
                houseId={houseId}
                menuId={menu?.MenuOption}
                formValues={fv}
                childFormValues={formValues.filter((fv2) =>
                  isChildOfForm(fv, fv2)
                )}
                fields={forms?.find(
                  (ff) =>
                    ff.FormName.toLowerCase() === fv.FormName.toLowerCase()
                )}
                formId={fv.FormName}
                dateToday={dateToday}
              />
            );
          })}
      </List>
      {loaded && formValues?.length === 0 && (
        <div className="flex flex-grow flex-col items-center justify-center w-full">
          <div className="text-sm text-center italic">
            <p>No historical data found.</p>
            {!isNull(menu) && (
              <p>
                Click <strong>New</strong> to add a record.
              </p>
            )}
          </div>
        </div>
      )}
    </>
  );
}
