import React, { useState, useEffect } from "react";
import useClickOutside from "hooks/useClickOutside";
import { usePopper } from "react-popper";
import { Input } from "components/core/Forms/Input";
import { SearchIcon, XIcon } from "assets/icons";
import { List, ListItem, Badge } from "components/core";
import useDebounceEffect from "hooks/useDebounceEffect";
import {
  extractTextFromJSX,
  isNull,
  isNullEmptyOrWhitespace,
} from "helpers/stringUtilities";

export default function ButtonOptions({
  options,
  title,
  filters,
  buttonElement,
  className,
  style,
  setShow,
  showStats,
  showSearch,
  onHideOptions,
  optionProps,
  ...other
}) {
  const [popperElement, setPopperElement] = useState(undefined);
  const [arrowElement, setArrowElement] = useState(undefined);
  const [filteredOptions, setFilteredOptions] = useState(undefined);
  const [searchQuery, setSearchQuery] = useState("");
  // Expected format: { Property: ["option1", "option2"]}
  const [optionsFilters, setOptionsFilters] = useState(filters);

  const { styles, attributes } = usePopper(buttonElement, popperElement, {
    // placement: "top-start",
    modifiers: [
      { name: "offset", options: { offset: [0, 10] } },
      { name: "arrow", options: { element: arrowElement } },
      // { name: "preventOverflow", options: { padding: 10 }} // Not working correctly
    ],
  });

  //#region Callbacks

  /**
   * Hides the button options when clicked outside.
   * @param {Event} ev
   * @returns {void}
   */
  function handleClickOutside(ev) {
    setShow(false);

    if (!!onHideOptions) onHideOptions();
  }

  /**
   * Handle clicking option
   * @param {Event} ev
   * @param {object} option
   */
  const handleOptionOnClick = (ev, option) => {
    ev.preventDefault();
    setShow(false);

    if (!!onHideOptions) onHideOptions();

    !!option.onClick && option.onClick();
  };

  /**
   * Handle change search string
   * @param {string} value
   */
  const handleChangeSearchQuery = (value) => {
    setSearchQuery(value);
  };

  /**
   * Remove option filter
   * @param {Number} index Remo
   */
  const removeFilter = (key, value) => {
    setOptionsFilters((prevState) => {
      const newState = prevState !== undefined ? { ...prevState } : {};
      newState[key] = newState[key].filter((v) => v !== value);

      // Delete key if no items left in array
      if (newState[key].length === 0) delete newState[key];

      return newState;
    });
  };

  //#endregion

  //#region Side-effects

  /**
   * Debounced, only trigger every X seconds
   * Listen for changes to options and reapply filter
   */
  useDebounceEffect(
    () => {
      let _filteredOptions = null;
      if (!isNull(options)) {
        const normalisedSearchQuery = searchQuery.toLowerCase();
        _filteredOptions = [...options].filter((opt) => {
          let result = true;

          // Search query
          if (typeof opt.text == "string") {
            result =
              opt.text.toLowerCase().includes(normalisedSearchQuery) || // Filter by text
              opt.id.toString().toLowerCase().includes(normalisedSearchQuery);
          } else if (typeof opt.text == "object") {
            let text = opt.text.props.children;
            // React element
            if (opt.text.props.children instanceof Array) {
              // Must be a React JSX object
              // Extract text from JSX
              text = extractTextFromJSX(opt.text);
            }
            result = text.toLowerCase().includes(searchQuery.toLowerCase());
          }

          // Meta
          if (
            !isNullEmptyOrWhitespace(opt.meta) &&
            result === true &&
            !isNullEmptyOrWhitespace(optionsFilters)
          ) {
            for (const [key, value] of Object.entries(optionsFilters)) {
              result = someValueInMeta(value, opt, key) ?? result;
            }
          }

          return result;
        });
        // setFilteredOptions(_filteredOptions);
        setFilteredOptions(_filteredOptions);
      }
    },
    [options, searchQuery, optionsFilters],
    500
  );

  /**
   * Set optionsFilters
   */
  useEffect(() => {
    if (filters === undefined) return;

    setOptionsFilters(filters);
  }, [filters]);

  //#endregion

  useClickOutside([buttonElement, popperElement], handleClickOutside);

  return !isNull(filteredOptions) && (
    <div
      {...other}
      className={`${className} popover z-20`}
      style={{ ...style, ...styles.popper }}
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabIndex="-1"
      ref={setPopperElement}
      {...attributes.popper}
    >
      <div className={`bg-gray-50 border-gray-200 shadow-md rounded-md border ml-2 mr-2`}>
        {showSearch && (
          <div className="border-b px-4 py-4">
            {title && title}
            <Input
              id="search"
              placeholder="Filter..."
              value={searchQuery}
              setValue={handleChangeSearchQuery}
              required={false}
              addonLeft={<SearchIcon className="w-4" />}
              addonRight={
                searchQuery && (
                  <XIcon className="w-4" onClick={() => setSearchQuery("")} />
                )
              }
              addonRightInteractable={true}
              size="sm"
              disableCalcTrigger={true}
            />
            {!isNullEmptyOrWhitespace(optionsFilters) && (
              <div className="text-sm mt-2">
                <span className="mr-2 text-gray-500">Filtered by:</span>
                {Object.entries(optionsFilters).map(([key, value]) =>
                  value.map((v) => (
                    <Badge
                      key={`badge-${key}-${v}`}
                      className="mr-1 mb-1 cursor-pointer"
                      theme="secondary"
                      iconPosition="right"
                      icon={<XIcon className="w-4 h-4" />}
                      onClick={() => removeFilter(key, v)}
                      title="Click to remove filter"
                    >
                      {key}: {v}
                    </Badge>
                  ))
                )}
              </div>
            )}
          </div>
        )}
        <div className="">
          <List {...optionProps} theme="striped">
            {filteredOptions?.map((option) => (
              <ListItem
                {...option}
                id={`menu-item-${option.id}`}
                key={`menu-item-${option.id}`}
                onClick={(ev) => handleOptionOnClick(ev, option)}
              >
                {option.text}
              </ListItem>
            ))}
          </List>
          {showStats && filteredOptions && options && (
            <div className="text-xs text-gray-500 p-4 text-center">
              Showing <strong>{filteredOptions.length}</strong> of{" "}
              <strong>{options.length}</strong> records
            </div>
          )}
        </div>
      </div>
      <div ref={setArrowElement} className="arrow text-gray-50" style={styles.arrow}>
        <svg viewBox="0 0 100 100">
          <polyline points="50 15, 100 100, 0 100" style={{ stroke: "currentColor", strokeWidth: 1 }} />
          <polygon
            points="50 15, 100 100, 0 100"
            style={{ fill: "currentColor", stroke: "currentColor", strokeWidth: 1 }}
          />
        </svg>
      </div>
    </div>
  );
}

/**
 * Determines whether a specified value exists in option metadata
 * @param {String|Array<String>} value        The needle
 * @param {{meta:String|Array<String>}} opt   The haystack
 * @param {String} key                        The meta key passed into the ButtonOption, e.g. "Group".
 * @returns
 */
function someValueInMeta(value, opt, key) {
  if (isNullEmptyOrWhitespace(value)) return;

  if (value instanceof Array) {
    // Array
    return value.some((v) => {
      if (opt.meta[key] instanceof Array) {
        // Array - e.g. ["1", "2", "3"]
        return opt.meta[key].some((r) => r.toLowerCase() === v.toLowerCase());
      } else {
        // String
        return v.toLowerCase() === opt.meta[key].toLowerCase();
      }
    });
  }
  // String
  return value.toLowerCase().includes(opt.meta[key].toLowerCase());
}
