export interface Farm {
  FarmCode: string;
  FarmGroup: string;
  FarmName: string;
  FarmType: string;
  Houses: FarmHouse[];
}

export interface FarmHouse {
  HouseLabel: string;
  HouseNumber: number;
  WaterMeterType: string;
  Pens: FarmPen[];
}

export interface FarmPen {
  PenNumber: number;
  BirdsAlive: number;
  Placement: FarmPlacement;
}

export interface FarmPlacement {
  HatchDate: string;
  CropDate: string;
  DatePlaced: string;
  DepopDate: string;
  BirdsPlaced: number;
  BirdSex: string;
  BirdType: string;
}

export function getFarm(farms: Farm[], farmCode: string): Farm | null {
  return farms.find(
    (farm) => farm.FarmCode?.toLowerCase() === farmCode.toLowerCase()
  ) ?? null;
}

export function getFarmHouse(farm: Farm, houseNumber: number): FarmHouse | null {
  return farm.Houses.find((house) => house.HouseNumber === houseNumber) ?? null;
}

export function getFarmHousePen(
  farmHouse: FarmHouse,
  penNumber: number
): FarmPen | null {
  return farmHouse.Pens.find((pen) => pen.PenNumber === penNumber) ?? null;
}
