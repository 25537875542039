import { useEffect, useState } from "react";
import Form from "./Form";
import { useNavigate, useParams } from "react-router-dom";
import Fieldset from "components/forms/Fieldset";
import { Button } from "components/core";
import { isNull } from "helpers/stringUtilities";
import { ChevronRightIcon, ChevronLeftIcon } from "assets/icons";
import useQuery from "hooks/useQuery";
import { groupObjectsFieldsBy } from "helpers/dataUtilities";
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";
import { redirectToListView } from "helpers/redirectUtilities";
import { getPenDataFromFormData } from "helpers/formUtilities";

/**
 * Component to render event fieldset.
 * @param {object} props
 * @returns {JSX} Returns JSX to render a House fieldset.
 */
export default function Generic({
  handleFormSubmit,
  dataStatus,
  farm,
  form,
  formValues,
  formValid,
  house,
  loaded,
  formProps,
  view,
  showSections = true,
  setAuditStatus,
  ...other
}) {
  const navigate = useNavigate();
  const { id } = useParams();
  const query = useQuery();
  const menuId = query.get("menuId");

  const [groupedFields, setGroupedFields] = useState(undefined);
  const [sections, setSections] = useState([]);
  const [selectedSection, setSelectedSection] = useState(undefined);

  //#region Callbacks

  //#endregion

  //#region Side-effects

  /**
   * Populate sections
   */
  useEffect(() => {
    if (isNull(form?.FormFields) || !showSections) return;

    const _sections = [];
    for (const field of form.FormFields) {
      if (!_sections.includes(field.Section)) {
        // Distinct only
        _sections.push(field.Section);
      }
    }

    // Add question count to each section
    const _sectionsWithCount = [];
    for (const section of _sections) {
      // count number of required form fields in section
      // Build an lookup array of required section refs
      const requiredFormFieldRefs = form?.FormFields.filter(
        (ff) =>
          ff.Required?.toLowerCase() === "d" &&
          ff.Section?.toLowerCase() === section?.toLowerCase()
      ).map((ff) => ff.Ref);

      let requiredCount = 0;
      let completedCount = 0;
      for (const fv of formValid) {
        if (requiredFormFieldRefs.includes(fv.Ref)) {
          // Total
          if (fv.Required) requiredCount += 1;
          // Completed
          if (fv.Required && fv.Complete) completedCount += 1;
        }
      }

      _sectionsWithCount.push({
        name: section,
        requiredCount,
        completedCount,
      });
    }

    setSections(_sectionsWithCount);
  }, [form?.FormFields, formValid, showSections]);

  /**
   * Set default selected section
   */
  useEffect(() => {
    if (selectedSection !== undefined || sections === undefined) return;

    // Default to first section
    if (sections.length > 0) {
      setSelectedSection(sections[0].name);
    }
  }, [sections, selectedSection]);

  /**
   * Group fields by QuestionGroup property
   */
  useDeepCompareEffectNoCheck(() => {
    if (!form?.FormFields.length) return;

    const _groupedFields = {
      house: groupObjectsFieldsBy(
        form?.FormFields.filter((ff) => ff.Level.toLowerCase() === "h"),
        "QuestionGroup"
      ),
      pen: groupObjectsFieldsBy(
        form?.FormFields.filter((ff) => ff.Level.toLowerCase() === "p"),
        "QuestionGroup"
      ),
    };

    for (const [levelKey, level] of Object.entries(_groupedFields)) {
      for (const [groupKey, group] of Object.entries(level)) {
        // Filter by grouped section
        // Important: to ensure that field value is validated correctly and included in form values
        // the field must still render.
        if (showSections) {
          _groupedFields[levelKey][groupKey].render = false;
          for (const [fieldKey, field] of Object.entries(group.fields)) {
            // Add field property to hide/show fields individually
            if (field.Section === selectedSection) {
              _groupedFields[levelKey][groupKey].fields[fieldKey].render = true;
              _groupedFields[levelKey][groupKey].render = true;
            } else {
              _groupedFields[levelKey][groupKey].fields[
                fieldKey
              ].render = false;
            }
          }
        } else {
          _groupedFields[levelKey][groupKey].render = true;
        }
      }
    }

    setGroupedFields(_groupedFields);
  }, [
    form?.FormFields,
    selectedSection,
    form?.NonConformanceFormFieldTemplates,
    view,
  ]);

  // /**
  //  * Set audit status
  //  */
  // useDeepCompareEffect(() => {
  //   if (
  //     menuId?.toLowerCase() !== "audit" ||
  //     isNullEmptyOrWhitespace(form?.FormFields) ||
  //     isNullEmptyOrWhitespace(formValues)
  //   )
  //     return;

  //   // Build primary question lookup map
  //   const _primaryQuestionRefs = new Map();

  //   for (const field of form.FormFields) {
  //     if (
  //       !isNullEmptyOrWhitespace(field.QuestionGroup) &&
  //       field.QuestionGroup === field.Ref
  //     ) {
  //       _primaryQuestionRefs.set(field.Ref.toLowerCase(), field);
  //     }
  //   }

  //   //Build set of non-conformant primary question refs
  //   const _nonConformancePrimaryQuestionRefs = new Set();

  //   for (const pen of formValues) {
  //     for (const pv of pen.Values) {
  //       const _primaryQuestionField = _primaryQuestionRefs.get(
  //         pv.Ref?.toLowerCase()
  //       );

  //       if (_primaryQuestionField) {
  //         if (!_primaryQuestionField.ListOptions) {
  //           console.error(`No ListOptions for ${_primaryQuestionField.Ref}`);
  //         }

  //         const listOption = _primaryQuestionField.ListOptions?.find(
  //           (lo) => lo.Value === pv.Value
  //         );

  //         const _hasNonConformance = !isNullEmptyOrWhitespace(listOption)
  //           ? listOption.IsNcn?.toString().toLowerCase() === "true"
  //           : false;
  //         if (_hasNonConformance) {
  //           _nonConformancePrimaryQuestionRefs.add(pv.Ref.toLowerCase());
  //         }
  //       }
  //     }
  //   }

  //   const _newAuditStatus =
  //     _nonConformancePrimaryQuestionRefs.size === 0 &&
  //     dataStatus === DATA_STATUS.COMPLETE
  //       ? AUDIT_STATUS.CLOSED
  //       : AUDIT_STATUS.OPEN;

  //   console.log("_newAuditStatus", _nonConformancePrimaryQuestionRefs, _newAuditStatus, dataStatus);

  //   setAuditStatus(_newAuditStatus);
  // }, [form.FormFields, formValues, setAuditStatus]);

  //#endregion

  //#region Event handlers

  /**
   * Handle click cancel button
   */
  const handleClickCancel = () => {
    return navigate(redirectToListView(window.location, menuId));
  };

  const handleSelectSection = (value) => {
    setSelectedSection(value);
  };

  //#endregion

  const hasPenFields = form?.FormFields.some(
    (ff) => ff.Level.toLowerCase() === "p"
  );
  const numPens = house?.Pens?.length;
  const pens = [];
  if (hasPenFields && groupedFields !== undefined) {
    for (let index = 1; index <= numPens; index++) {
      pens.push(
        <Fieldset
          {...other}
          key={`fieldset-pen${index}`}
          title={`Pen ${index}`}
          text={`This section contains information regarding Pen ${index}.`}
          penNumber={index}
          groupedFields={groupedFields.pen}
          formValues={getPenDataFromFormData(index.toString(), formValues)}
          farm={farm}
          collapsible
        />
      );
    }
  }

  const SectionSelectButton = () => {
    return (
      <Button
        optionsContainerPosition="bottom"
        theme="text"
        className="normal-case text-gray-500"
        options={sections?.map((s) => ({
          id: s.name,
          text: (
            <div className="flex flex-grow items-center">
              <div className="flex-grow">{s.name}</div>
              {s.requiredCount > 0 && (
                <div
                  className={`${
                    s.requiredCount > 0 && s.completedCount >= s.requiredCount
                      ? "text-success-500 "
                      : ""
                  }items-end ml-2 text-xs`}
                >
                  {s.completedCount}/{s.requiredCount}
                </div>
              )}
            </div>
          ),
          onClick: () => handleSelectSection(s.name),
        }))}
        optionsClassName="overflow-y-auto"
        optionsStyles={{ maxHeight: "60vh" }}
        optionProps={{ size: "small" }}
      >
        {selectedSection}
      </Button>
    );
  };

  return (
    <Form
      {...formProps}
      onClickCancel={handleClickCancel}
      onFormSubmit={(ev) => {
        ev.preventDefault();
        handleFormSubmit(ev, { entryId: id });
      }}
      dataStatus={dataStatus}
      loaded={loaded && sections !== undefined}
      showDraft={menuId?.toLowerCase() === "audit"}
      button={selectedSection ? <SectionSelectButton /> : "Section"}
    >
      <Fieldset
        {...other}
        key={`fieldset-house1`}
        penNumber="1"
        groupedFields={groupedFields?.house}
        formValues={getPenDataFromFormData("1", formValues)}
        farm={farm}
        title={selectedSection ?? `Section`}
        text={selectedSection ? null : undefined}
        loaded={loaded && sections !== undefined}
        content={
          selectedSection ? (
            <SectionIndicator
              sections={sections}
              selectedSection={selectedSection}
              onSelectSection={handleSelectSection}
            />
          ) : null
        }
      />
      {pens}
    </Form>
  );
}

const SectionIndicator = ({
  sections,
  selectedSection,
  onSelectSection,
  className,
  ...other
}) => {
  const selectedSectionIndex = sections.findIndex(
    (s) => s.name === selectedSection
  );

  return (
    <div {...other} className={`${className} text-gray-600 text-sm`}>
      <div className="mt-2 flex space-x-2">
        <Button
          disabled={sections.length < 2 || selectedSectionIndex - 1 < 0}
          size="small"
          theme="text"
          icon={<ChevronLeftIcon className="w-4 h-4" />}
          onClick={() =>
            onSelectSection(sections[selectedSectionIndex - 1].name)
          }
        >
          Prev
        </Button>
        <Button
          disabled={
            sections.length < 2 ||
            selectedSectionIndex + 1 > sections.length - 1
          }
          size="small"
          theme="text"
          iconPosition="right"
          icon={<ChevronRightIcon className="w-4 h-4" />}
          onClick={() =>
            onSelectSection(sections[selectedSectionIndex + 1].name)
          }
        >
          Next
        </Button>
      </div>
    </div>
  );
};
