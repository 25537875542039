import React, { useContext, useState, useEffect, useRef } from "react";
import Breadcrumb from "components/Breadcrumb";
import PageHeader from "components/PageHeader";
import Historical from "components/Historical";
import { useNavigate } from "react-router-dom";
import { AppDataContext } from "context/AppDataProvider";
import { NavContext } from "context/NavProvider";
import { NotificationContext } from "context/NotificationProvider";
import useQuery from "hooks/useQuery";
import { isNull, isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { LOADED_STATUS } from "constants.js";
import PageNotFound from "components/PageNotFound";
import ButtonNew from "components/Historical/ButtonNew";
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";

export default function ListPage() {
  let navigate = useNavigate();
  let query = useQuery();
  const farmId = query.get("farmId");
  const houseId = query.get("houseId");
  const menuId = query.get("menuId");
  const { menus, farms, fetchFormTemplates } = useContext(AppDataContext);
  const { setOptionsToShow } = useContext(NavContext);
  const { addNotification } = useContext(NotificationContext);
  const [farm, setFarm] = useState(undefined);
  const [forms, setForms] = useState(undefined);
  const [formIds, setFormIds] = useState([]);
  const [menu, setMenu] = useState(undefined);
  const [loaded, setLoaded] = useState(LOADED_STATUS.LOADING);
  const [newButtonOptions, setNewButtonOptions] = useState([]);

  const abortControllerRef = useRef(undefined);

  //#region Event handlers

  const handleButtonNewClick = (formId) => {
    const searchParams = new URLSearchParams(
      window.location.search.substring(1)
    );
    searchParams.set("formId", formId);
    navigate({
      pathname: "/forms/new",
      search: "?" + searchParams.toString(),
    });
  };

  //#endregion

  //#region Side-effects

  /**
   * Mount/Unmount
   */
  useEffect(
    () => {
      abortControllerRef.current = new AbortController(); // Should always come first

      return () => {
        abortControllerRef.current.abort();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  /**
   * Set form types
   */
  useEffect(() => {
    if (!menus?.length) return;

    const _formTypes = menus
      .filter((m) => m.MenuOption.toLowerCase() === menuId.toLowerCase())
      .reduce((result, m) => {
        result.push(
          ...m.Forms.map((f) => ({
            formId: f.FormName.toLowerCase(),
            menuOption: m.MenuOption.toLowerCase(),
            menuOptionID: m.ID.toString(),
          }))
        );
        return result;
      }, []);

    setFormIds(_formTypes);
  }, [menus, menuId]);

  /**
   * Set farm
   */
  useEffect(() => {
    if (!farmId || !farms?.length) return;

    setFarm(
      farms.find((farm) => farm.FarmCode.toLowerCase() === farmId.toLowerCase())
    );
  }, [farmId, farms]);

  /**
   * Set menu
   */
  useEffect(() => {
    if (!menuId || !menus.length || !farm?.FarmGroup) return;

    // Fetch forms for selected farm group

    // Filter menus
    const newFilteredMenus = menus.filter((m) => {
      let shouldShow = true;

      // Allowed farm groups
      if (m.Metadata?.Display?.FarmGroups) {
        shouldShow = m.Metadata.Display.FarmGroups.some(
          (dfg) => dfg.toLowerCase() === farm.FarmGroup.toLowerCase()
        );
      }

      return shouldShow;
    });

    const menu = newFilteredMenus?.find(
      (m) => m.MenuOption.toLowerCase() === menuId.toLowerCase()
    );

    setMenu(menu ?? null);
  }, [
    menus,
    menuId,
    farm?.FarmGroup,
    farm?.FarmCode,
    addNotification,
    setOptionsToShow,
  ]);

  /**
   * Set form templates
   */
  useEffect(() => {
    if (!farm?.FarmGroup || !fetchFormTemplates) return;
    const { signal } = abortControllerRef.current;

    const _fetchFormTemplate = (farmGroup) => {
      farmGroup = farmGroup?.toLowerCase();

      fetchFormTemplates(farmGroup, signal)
        .then((data) => {
          if (signal.aborted) return;

          const forms = data.filter((f) =>
            formIds.some(
              (fi) =>
                fi.formId === f.FormName.toLowerCase() ||
                fi.menuOptionID.toString() === f.MenuOptionID.toString()
            )
          );

          if (isNullEmptyOrWhitespace(forms)) return setForms(null);

          return setForms(forms);
        })
        .catch((error) => {
          if (signal.aborted) return;
          console.error(error.message);
        });
    };

    _fetchFormTemplate(farm.FarmGroup);
  }, [farm?.FarmGroup, fetchFormTemplates, formIds]);

  /**
   * Set loaded
   */
  useEffect(() => {
    if (!isNull(forms) && !isNull(menu)) {
      setLoaded(LOADED_STATUS.LOADED);
    } else if (
      loaded !== LOADED_STATUS.ERROR &&
      (forms === null || menu === null)
    ) {
      // no menu found for house
      // display warning notification
      addNotification({
        title: "Invalid farm selection",
        theme: "warning",
        description: (
          <span>
            Page not found for farm <em>{farm.FarmCode}</em>. Try selecting a
            different page or farm.
          </span>
        ),
      });
      // show farm selection
      setOptionsToShow("farm");
      setLoaded(LOADED_STATUS.ERROR);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forms, menu]);

  /**
   * Set new button options
   */
  useDeepCompareEffectNoCheck(() => {
    const newButtonOptions = menu?.Forms?.filter(
      (m) =>
        !["nonconformance", "report"].includes(m.FormName.toLowerCase()) &&
        m.ActivityCode !== 1
    );

    setNewButtonOptions(newButtonOptions ?? []);
  }, [menu?.Forms]);

  //#endregion

  return (
    <div className="flex flex-col flex-grow overflow-x-hidden">
      <div className="relative z-20">
        <Breadcrumb showHome={false} farmRequired={true} houseRequired={true} />
        {menu !== null && (
          <PageHeader
            title={menu?.MenuName}
            loaded={menu !== undefined}
            className="py-6 px-4 sm:px-6 lg:px-8 flex flex-row"
          >
            {newButtonOptions?.length > 0 && (
              <div className="flex flex-grow justify-end items-center">
                <ButtonNew
                  onClick={handleButtonNewClick}
                  options={newButtonOptions}
                />
              </div>
            )}
          </PageHeader>
        )}
      </div>
      <main className="flex flex-grow flex-col">
        {loaded === LOADED_STATUS.ERROR ? (
          <div className="flex flex-grow items-center justify-center">
            <div className="flex-grow items-center justify-center text-sm text-center italic">
              <div className="p-2">
                <PageNotFound
                  selectedFarm={farm.FarmName}
                  selectedHouse={houseId}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col flex-grow">
            <Historical
              farmId={farmId?.toLowerCase()}
              houseId={houseId?.toString()}
              menu={menu}
              forms={forms}
              formIds={formIds}
            />
          </div>
        )}
      </main>
    </div>
  );
}
