/* This example requires Tailwind CSS v2.0+ */
import { CheckCircleIcon } from "@heroicons/react/outline";
import { XCircleIcon } from "assets/icons";
import { ExclamationCircleIcon } from "assets/icons/ExclamationCircleIcon";
import classNames from "classnames";

interface AlertProps {
  className?: string;
  theme?: "danger" | "success" | "warning";
  children: React.ReactNode;
}

export default function Alert({ theme, children, ...rest }: AlertProps) {
  return (
    <div
      {...rest}
      className={classNames(
        "rounded-md p-4 border shadow-sm",
        theme === "danger" && "bg-danger-50 border-danger-100",
        theme === "success" && "bg-success-50 border-success-100",
        theme === "warning" && "bg-warning-50 border-warning-100"
      )}
    >
      <div className="flex">
        <div className="flex-shrink-0">
          {theme === "danger" && (
            <XCircleIcon
              className="h-5 w-5 text-danger-400"
              aria-hidden="true"
            />
          )}
          {theme === "success" && (
            <CheckCircleIcon
              className="h-5 w-5 text-success-400"
              aria-hidden="true"
            />
          )}
          {theme === "warning" && (
            <ExclamationCircleIcon
              className="h-5 w-5 text-warning-400"
              aria-hidden="true"
            />
          )}
        </div>
        <div className="ml-3">
          <div
            className={classNames(
              "mt-2 text-sm",
              theme === "danger" && "text-danger-700",
              theme === "success" && "text-success-700",
              theme === "warning" && "text-warning-700"
            )}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
