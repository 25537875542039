import React, { useRef } from "react";
import PropTypes from "prop-types";
import BreadcrumbItemSkeleton from "./BreadcrumbItemSkeleton";
import { Button } from "components/core";

const BreadcrumbItem = ({
  title,
  icon,
  onClick,
  subtitle,
  showDivider = true,
  loaded = true,
  showOptions,
  onShowOptions,
  onHideOptions,
  options,
  optionsHeading,
  optionsFilteredByMeta,
}) => {
  const liRef = useRef(undefined);

  if (!loaded) {
    return <BreadcrumbItemSkeleton showDivider={showDivider} />;
  }

  return (
    <li data-cy="breadcrumb-item" ref={liRef} className="flex whitespace-nowrap ">
      <div className="flex items-center">
        <Button
          className="mx-4 py-1 text-left text-sm text-gray-600 hover:text-gray-700 focus:outline-none"
          onClick={onClick}
          options={options}
          showOptions={showOptions}
          onShowOptions={() => !!onShowOptions && onShowOptions(liRef.current)}
          onHideOptions={onHideOptions}
          theme="text"
          optionsHeading={optionsHeading}
          optionsFilteredByMeta={optionsFilteredByMeta}
          optionsClassName="w-full tablet:max-w-sm"
          showStats={true}
          showSearch={true}
        >
          <div className="flex items-center space-x-2">
            {!!icon && <div>{icon}</div>}
            {(title || subtitle) && (
              <div>
                {title && (
                  <div className="text-sm font-medium text-primary hover:text-primary-dark w-full block truncate ">
                    {title}
                  </div>
                )}
                {subtitle && (
                  <div className="text-xs w-full text-gray-400 truncate ">
                    {subtitle}
                  </div>
                )}
              </div>
            )}
          </div>
        </Button>
        {showDivider && (
          <svg
            className="flex-shrink-0 w-6 h-full text-gray-200"
            viewBox="0 0 24 44"
            preserveAspectRatio="none"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
          </svg>
        )}
      </div>
    </li>
  );
};

BreadcrumbItem.propTypes = {
  title: PropTypes.string,
  Icon: PropTypes.node,
  onClick: PropTypes.func,
};

export default BreadcrumbItem;
