import { Modal } from "components/core";
import React, { useState, createContext } from "react";

export const ModalContext = createContext(undefined);

export function ModalProvider({ children }) {
  const [show, setShow] = useState(false);
  const [content, setContent] = useState(undefined);

  const setShowModal = (show, content) => {
    if (show) {
      setContent(content);
      setShow(true);
    } else {
      setContent(undefined);
      setShow(false);
    }
  };

  return (
    <ModalContext.Provider
      value={{
        setShowModal,
      }}
    >
      {children}
      <Modal open={show} onOpen={(_open) => setShow(_open)} showCancel={false}>
        {content}
      </Modal>
    </ModalContext.Provider>
  );
}
