import {
  dateAdd,
  dateDiffInMilliseconds,
  dateSubtract,
  localDate,
  localDateFromSQL,
  localDateToSQL,
  sqlDateObjectFromServerTZ,
  startOfFromDate,
} from "./dateUtilities";
import { Farm } from "./farmUtilities";
import {
  calcAverage,
  calcCoefficiencyVariance,
  calcStandardDeviation,
} from "./mathUtilities";
import { isNullEmptyOrWhitespace, isNumeric } from "./stringUtilities";

const DEFAULT_DATASOURCE = "this";
const DEFAULT_PROPERTY = "Value";

export interface ListOption {
  Id: string | number;
  Days: number;
  IsNcn: boolean;
  Parent: string | null;
  Position: number;
  Score: number | null;
  SeverityColour: number | null;
  Text: string;
  Value: string;
}

export interface FormField {
  Ref: string;
  QuestionGroup: string | null;
  Name: string;
  Calculation: string | null;
  DefaultValue: string | null;
  Description: string | null;
  Display: string | null;
  FarmType: string | null;
  FieldType: string | null;
  Level: string | null;
  List: string | null;
  ListOptions: ListOption[] | null;
  MaxTol: number | null;
  MinTol: number | null;
  Position: number;
  Prefix: string | null;
  Required: "D" | "A" | null;
  Section: string | null;
  Std: string | number | null;
  Suffix: string | null;
  Validation: string | null;
}

export interface FileUploadValue {
  saved: any[];
  pending: any[];
  deleting: any[];
}

export interface FormValue {
  Ref: string;
  Value: string | FileUploadValue;
  QuestionGroup: string | null;
  Score: number | null;
  Text: string | null;
}

export interface BirdsAliveData {
  BirdsAlive: number;
  FemaleAlive: number;
  MaleAlive: number;
}

export interface PenData {
  Pen: string;
  Values: FormValue[];
  BirdsAlive: BirdsAliveData;
}

export interface FormData {
  ID: number | null;
  AuditStatus: number | null;
  DateApplies: string;
  _DateApplies: {
    native: Date;
    normalised: Date;
    localised: Date;
    dateString: String;
    timeString: String;
  };
  House: number;
  LastModified: string;
  ParentPWAID: string | null;
  PenValues: PenData[];
}

export interface FarmStandard {
  ID: string;
  BirdSex: string;
  BirdType: string;
  Days: number;
  FarmGroup: string;
  StdName: string;
  StdNo: number;
  Value: number | string;
}

export interface BirdAge {
  Days: number;
  Weeks: number;
}

export interface CustomLogicDataSource {
  [key: string]: FormData | FarmStandard[];
}

export interface CustomLogicParams {
  PenId: string;
  GroupId: string;
  BirdType: string;
  BirdSex: string;
  BirdAge: BirdAge;
  FarmGroup: string;
}

export function getPrimaryQuestionFormValue(
  penData: PenData
): FormValue | null {
  if (!penData?.Values?.length) return null;

  const penParentPrimaryQuestion = penData.Values.find(
    (value) => value.QuestionGroup === "PenParentPrimaryQuestion"
  );
  if (!penParentPrimaryQuestion) {
    throw new Error("PenParentPrimaryQuestion not found");
  }
  return {
    Ref: penParentPrimaryQuestion.Ref,
    Value: penParentPrimaryQuestion.Value,
    QuestionGroup: penParentPrimaryQuestion.QuestionGroup,
    Score: null,
    Text: null,
  };
}

export function getPenDataFromFormData(
  penId: string,
  data: FormData
): PenData | undefined {
  if (!data?.PenValues?.length) return;

  const penValues = data.PenValues.find(
    (fv) => fv.Pen.toString() === penId.toString()
  );

  return penValues;
}

export function getCustomLogicDataSourceFormData(
  datasource: string | null,
  data: CustomLogicDataSource
): FormData | FarmStandard[] | Farm | null {
  datasource = datasource?.toLowerCase() ?? "this";

  const result = data[datasource] ?? null;

  //Uncomment for debugging
  //console.log("data", data, "datasource", datasource, "result", result);

  return result;
}

export interface CustomLogicVariable {
  ref: string | null;
  property: string;
  datasource: string;
  group: string | null;
  pen: string | null;
  func: {
    name: string;
    args: unknown[];
  };
}

export interface CustomLogicFuncParams {
  name: string;
  args: any[];
}

/**
 * @example
 * parseCustomLogicVariable("${V1.Text}")
 */
export function parseCustomLogicVariable(
  customLogicString: string,
  params: CustomLogicParams
): CustomLogicVariable {
  // const _originalCustomLogicString = customLogicString;
  const _result: CustomLogicVariable = {
    ref: "",
    property: "",
    datasource: "",
    group: null,
    pen: null,
    func: {
      name: "",
      args: [],
    },
  };

  (function convertLegacyCustomLogicString(): void {
    customLogicString = customLogicString.replace(
      /^house:birdsalive$/i,
      "_birdsalive -p all"
    );

    customLogicString = customLogicString.replace(
      /^house:birdsalive:male$/i,
      "_birdsalive.male -p all"
    );

    customLogicString = customLogicString.replace(
      /^house:birdsalive:female$/i,
      "_birdsalive.female -p all"
    );

    customLogicString = customLogicString.replace(
      /^pen:birdsalive$/i,
      "_birdsalive"
    );

    customLogicString = customLogicString.replace(
      /^pen:birdsalive:male$/i,
      "_birdsalive.male"
    );

    customLogicString = customLogicString.replace(
      /^pen:birdsalive:female$/i,
      "_birdsalive.female"
    );

    if (customLogicString.startsWith("datediff:")) {
      customLogicString = customLogicString.replace(
        /datediff:([^,]+),([^,]+)/i,
        "$1 -f datediff($$$2)"
      );
    }

    if (customLogicString.startsWith("date:")) {
      customLogicString = customLogicString.replace(/date:this/i, "_formdate)");

      customLogicString = customLogicString.replace(
        /date:today/i,
        "_datetoday"
      );
    }

    if (customLogicString.startsWith("yesterday:")) {
      customLogicString = customLogicString.replace(
        /yesterday:([^,]+)/i,
        "$1 -d previous"
      );
    }

    if (customLogicString.startsWith("cv:")) {
      customLogicString = customLogicString.replace("cv:", "");
      const _variables = customLogicString.split(",");
      if (_variables === null) return;

      const firstVar = _variables?.shift();
      if (firstVar === undefined) return;

      customLogicString = `${firstVar} -f cv(${_variables
        .map((_var: string) => `$${_var}`)
        .join(",")})`;
    }

    if (customLogicString.startsWith("avg:")) {
      customLogicString = customLogicString.replace("avg:", "");
      const _variables = customLogicString.split(",");
      if (_variables === null) return;

      const firstVar = _variables?.shift();
      if (firstVar === undefined) return;

      customLogicString = `${firstVar} -f avg(${_variables
        .map((_var: string) => `$${_var}`)
        .join(",")})`;
    }

    if (customLogicString.startsWith("sd:")) {
      customLogicString = customLogicString.replace("sd:", "");
      const _variables = customLogicString.split(",");
      if (_variables === null) return;

      const firstVar = _variables?.shift();
      if (firstVar === undefined) return;

      customLogicString = `${firstVar} -f sd(${_variables
        .map((_var: string) => `$${_var}`)
        .join(",")})`;
    }
  })();

  function getOptions(): string[] | null {
    const _regex = /((?:-[a-z])(?:\s)*(?:[a-z0-9_]+(?:\([^()]+\))?)?)/gi;

    return customLogicString.match(_regex) ?? null;
  }
  const _options = getOptions();

  function getFlagValue(flag: string): { flag: string; value: string } | null {
    if (!_options?.length) return null;

    // const _regex = new RegExp(`(?:-${flag})(?:\\s)+([\\S]+)`, "i");
    // const _regex = new RegExp(`(?:-${flag})(?:\\s)+([a-z0-9]+(?:\\([^\\(\\)]+\\))?)`, "i");
    // const _regex = new RegExp(
    //   `(?<![(](?:[^)]+))-(${flag})(?:\\s)*([a-z0-9_]+(?:\\([^\\(\\)]+\\))?)?`,
    //   "i"
    // );

    // const _regex = new RegExp(`^[^(]+-(${flag})(?:\\s)*([a-z0-9_]+(?:\\([^\\(\\)]+\\))?)?`, 'i')
    const _regex = new RegExp(
      `^-(${flag})(?:\\s)*([a-z0-9_]+(?:\\([^\\(\\)]+\\))?)?`,
      "i"
    );

    for (const _option of _options) {
      const _match = _option.match(_regex);
      // console.log(customLogicString, _option, _match);

      if (_match) {
        const flag = _match[1]?.toLowerCase() ?? "";
        const value = _match[2]?.toLowerCase() ?? "";
        return { flag, value } ?? null;
      }
    }

    return null;
  }

  function getDataSource(): string {
    const dataSource = getFlagValue("d");

    return dataSource?.value?.toLowerCase() ?? DEFAULT_DATASOURCE;
  }
  _result.datasource = getDataSource();

  function getPen(): string | null {
    const pen = getFlagValue("p");

    if (pen?.value === "all") {
      // All pens
      return null;
    }

    // Default to the current 'PenId'
    if (pen === null || isNullEmptyOrWhitespace(pen.value)) {
      if (isNullEmptyOrWhitespace(params?.PenId)) {
        console.error(
          "No current 'PenId' provided and a pen flag was not found e.g. '-p 1'"
        );

        return null;
      }

      return params.PenId;
    }

    return pen.value.toLowerCase();
  }
  _result.pen = getPen();

  function getFunc(): CustomLogicFuncParams {
    const func = getFlagValue("f");
    const funcString = func?.value?.toLowerCase() ?? "";
    if (isNullEmptyOrWhitespace(funcString)) {
      return {
        name: "",
        args: [],
      };
    }

    const funcNameMatch = funcString.match(/^([a-z0-9]+)/i);
    const funcName = funcNameMatch?.[1] ?? null;

    const paramString = funcString.match(/\(([^)]+)\)/i);
    const params = paramString?.[1]?.split(",");

    return {
      name: funcName ?? "",
      args: params ?? [],
    };
  }
  _result.func = getFunc();

  function getGroup(): string | null {
    const group = getFlagValue("g");
    if (group === null) {
      return params?.GroupId ?? null;
    }

    return group?.value?.toLowerCase() ?? null;
  }
  _result.group = getGroup();

  function getRef(): string | null {
    const _regex = /^([a-z0-9\-_]+)/i;
    const _match = customLogicString.match(_regex);
    let ref = _match ? _match[1].toLowerCase() : null;

    // // Static refs
    // if (isNullEmptyOrWhitespace(_result.group) && ref === "_groupparent") {
    //   _result.group = "this";
    // }

    return ref;
  }
  _result.ref = getRef();

  function getProperty(): string {
    const _regex = /^[a-z0-9:\-_]+\.([a-z0-9]+)/i;
    const _match = customLogicString.match(_regex);

    const property = _match ? _match[1] : DEFAULT_PROPERTY;
    const normalisedPropetrty =
      property.toLowerCase().charAt(0).toUpperCase() + property.slice(1);

    return normalisedPropetrty;
  }
  _result.property = getProperty();

  return _result;
}

export function parseCustomLogic(
  customLogicString: string,
  data: CustomLogicDataSource,
  params: CustomLogicParams
): string {
  if (
    customLogicString === undefined ||
    customLogicString === null ||
    customLogicString === "" ||
    typeof customLogicString != "string"
  )
    return customLogicString;

  const _regex = /\${([^{}]+)}/gi;
  const _result = new Map<string, string>();

  const customLogicStringExclCustomVariables = customLogicString
    .replace(/\${([^{}]+)}/g, "")
    .trim();
  const isMath = containsMathOperators(customLogicStringExclCustomVariables);

  function getPrevCharacter(index: number): string {
    let i = index;
    do {
      i -= 1;
      const char = customLogicString.charAt(i);
      if (/\S/.test(char)) {
        return char;
      }
    } while (i > 0);

    return "";
  }

  function getNextCharacter(index: number): string {
    let i = index;
    do {
      i += 1;
      const char = customLogicString.charAt(i);
      if (/\S/.test(char)) {
        return char;
      }
    } while (i <= customLogicString.length);

    return "";
  }

  function containsMathOperators(value: string): boolean {
    return /[+\-*/^%]/.test(value);
  }

  function normaliseValue(
    value: string | number,
    prevCharIndex: number,
    nextCharIndex: number
  ): string {
    let result = value ?? "";
    if (isNullEmptyOrWhitespace(value) && isMath) {
      const prevChar = getPrevCharacter(prevCharIndex);
      const nextChar = getNextCharacter(nextCharIndex);

      if (["/", "*"].includes(prevChar) || ["*"].includes(nextChar)) {
        result = "1";
      } else {
        result = "0";
      }
    }

    return result.toString();
  }

  let _matches;
  while ((_matches = _regex.exec(customLogicString)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (_matches.index === _regex.lastIndex) {
      _regex.lastIndex++;
    }

    const prevCharIndex = _matches.index;
    const nextCharIndex = _regex.lastIndex - 1;

    for (const [i, m] of _matches.entries()) {
      if (i === 0) continue;

      const { ref, property, datasource, group, pen, func } =
        parseCustomLogicVariable(m, params);

      if (!ref) continue;

      const key = getKey(_matches.index, ref, group, pen, datasource);

      //Uncomment for debugging
      // prettier-ignore
      // console.log("ref", ref, "property", property, "datasource", datasource, "group", group, "pen", pen, "func", func);
      // console.log("key", key)

      const _dataSource = getCustomLogicDataSourceFormData(datasource, data);
      if (!_dataSource) {
        throw new Error(
          `An attempt to access datasource '${datasource}' for custom logic "${customLogicString}" failed. Either remove the calculation or add the datasource.`
        );
      }

      if (datasource === "_standards") {
        // Standards
        const _standards = _dataSource as FarmStandard[];

        const formValue = getFarmStandardsValue(_standards, ref);

        const normalisedValue = normaliseValue(
          formValue,
          prevCharIndex,
          nextCharIndex
        );

        _result.set(key, normalisedValue);

        continue;
      }

      if (datasource === "farm") {
        // Farms
        const farmData = _dataSource as Farm;

        const formValue = getFarmValue(farmData, ref);

        const normalisedValue = normaliseValue(
          formValue,
          prevCharIndex,
          nextCharIndex
        );

        _result.set(key, normalisedValue);

        continue;
      }

      //Form data

      const _formData = _dataSource as FormData;
      if (isNullEmptyOrWhitespace(_formData)) return "";

      if (ref === "_formdate") {
        let formValue = getFormDateValue(_formData, property);

        if (!isNullEmptyOrWhitespace(formValue)) {
          formValue = applyFunction(func, formValue)?.toString();
        }

        const normalisedValue = normaliseValue(
          formValue,
          prevCharIndex,
          nextCharIndex
        );

        _result.set(key, normalisedValue);

        continue;
      }

      if (ref === "_datetoday") {
        let formValue = getDateTodayValue(_formData, property);

        if (!isNullEmptyOrWhitespace(func?.name)) {
          formValue = applyFunction(func, formValue)?.toString();
        }

        const normalisedValue = normaliseValue(
          formValue,
          prevCharIndex,
          nextCharIndex
        );

        _result.set(key, normalisedValue);

        continue;
      }

      for (const penValue of _formData.PenValues) {
        if (!isNullEmptyOrWhitespace(pen) && pen !== penValue.Pen.toString())
          continue; // Skip pen

        let formValue;

        if (ref === "_birdsalive") {
          formValue = getPenBirdsAliveValue(penValue, property);
        } else if (ref === "_groupparent") {
          formValue = getGroupParentValue(
            penValue,
            ref,
            group,
            property
          )?.toString();
        } else {
          formValue = getFormValue(penValue, ref, group, property)?.toString();
        }

        if (!isNullEmptyOrWhitespace(func?.name)) {
          formValue = applyFunction(func, formValue);
        }

        const normalisedValue = normaliseValue(
          formValue,
          prevCharIndex,
          nextCharIndex
        );
        let result = normalisedValue;
        // console.log("formValue", formValue, "result", result, "key", key);
        if (!isNullEmptyOrWhitespace(result)) {
          // Append previous values with the same key
          const prevValue = _result.get(key);
          result =
            prevValue && isNumeric(prevValue)
              ? (parseInt(prevValue) + parseInt(normalisedValue)).toString()
              : normalisedValue;
        }

        _result.set(key, result);
      }
    }
  }

  //Replace all variables with their values
  const _resultArray = Array.from(_result.values()) ?? [];
  const result = customLogicString.replace(
    _regex,
    () => _resultArray.shift() ?? ""
  );

  return result;

  function getFormDateValue(formData: FormData, property: string): string {
    const formDate = formData._DateApplies.normalised;

    return localDateToSQL(formDate, { includeOffset: false });
  }

  function getDateTodayValue(formData: FormData, property: string): string {
    const dateToday = localDate();

    return localDateToSQL(dateToday, { includeOffset: false });
  }

  function getFormValue(
    penValue: PenData,
    ref: string,
    group: string | null,
    property: string
  ): string | number | FileUploadValue {
    return (
      penValue.Values.find(
        (fv) =>
          fv.Ref.toLowerCase() === ref.toLowerCase() &&
          (isNullEmptyOrWhitespace(group) ||
            fv.QuestionGroup?.toLowerCase() === group?.toLowerCase())
      )?.[(property ?? DEFAULT_PROPERTY) as keyof FormValue] ?? ""
    );
  }

  function getGroupParentValue(
    penValue: PenData,
    ref: string,
    group: string | null,
    property: string
  ): string | number | FileUploadValue {
    return (
      penValue.Values.find(
        (fv) => fv.Ref.toLowerCase() === group?.toLowerCase()
      )?.[(property ?? DEFAULT_PROPERTY) as keyof FormValue] ?? ""
    );
  }

  function getKey(
    matchIndex: number,
    ref: string,
    group: string | null,
    pen: string | null,
    datasource: string
  ): string {
    return `${matchIndex}:${ref}${group ? `-g${group}` : ""}${
      pen ? `-p${pen}` : ""
    }${datasource ? `-d${datasource}` : ""}`;
  }

  function getFarmStandardsValue(
    _standards: FarmStandard[],
    ref: string
  ): string {
    if (isNullEmptyOrWhitespace(params?.FarmGroup))
      throw new Error(
        "Farm group is required when accessing the standards datasource"
      );
    if (isNullEmptyOrWhitespace(params?.BirdType))
      throw new Error(
        "Bird type is required when accessing the standards datasource"
      );
    if (isNullEmptyOrWhitespace(params?.BirdSex))
      throw new Error(
        "Bird sex is required when accessing the standards datasource"
      );
    if (isNullEmptyOrWhitespace(params?.BirdAge?.Days))
      throw new Error(
        "BirdAge.Days is required when accessing the standards datasource"
      );

    const standard = _standards.find(
      (s) =>
        s.ID === ref &&
        s.FarmGroup === params.FarmGroup &&
        s.BirdType === params.BirdType &&
        s.BirdSex === params.BirdSex &&
        s.Days === params.BirdAge.Days
    );

    return standard?.Value?.toString() ?? "0";
  }

  function getFarmValue(farm: Farm, ref: string): string {
    const key = Object.keys(farm).find((k) => k.toLowerCase() === ref);
    if (key === undefined) return "";
    
    const result = farm?.[key as keyof Farm];

    return JSON.stringify(result);
  }

  function getPenBirdsAliveValue(penData: PenData, property: string): number {
    if (isNullEmptyOrWhitespace(penData)) return 0;

    property = property?.toLowerCase();

    if (property === "male") {
      const birdsalive = penData?.BirdsAlive?.MaleAlive ?? 0;

      // Total dead
      const totaldead = parseInt(
        penData?.Values?.find(
          (fv) => fv.Ref.toLowerCase() === "totaldeadmale"
        )?.Value?.toString() ?? "0"
      );

      // Birds removed
      const malesRemoved = parseInt(
        penData?.Values?.find(
          (v) => v.Ref.toLowerCase() === "totalmaleremoved"
        )?.Value?.toString() ?? "0"
      );

      return birdsalive - totaldead - malesRemoved;
    }

    if (property === "female") {
      const birdsalive = penData?.BirdsAlive?.FemaleAlive ?? 0;

      // Total dead
      const totaldead = parseInt(
        penData?.Values?.find(
          (fv) => fv.Ref.toLowerCase() === "totaldeadfemale"
        )?.Value?.toString() ?? "0"
      );

      // Birds removed
      const femalesRemoved = parseInt(
        penData?.Values?.find(
          (v) => v.Ref.toLowerCase() === "totalfemaleremoved"
        )?.Value?.toString() ?? "0"
      );

      return birdsalive - totaldead - femalesRemoved;
    }

    const birdsalive = penData?.BirdsAlive?.BirdsAlive ?? 0;

    // Total dead
    const totaldead = parseInt(
      penData?.Values?.find(
        (fv) => fv.Ref.toLowerCase() === "totaldead"
      )?.Value?.toString() ?? "0"
    );

    // Birds removed
    const malesRemoved = parseInt(
      penData?.Values?.find(
        (v) => v.Ref.toLowerCase() === "totalmaleremoved"
      )?.Value?.toString() ?? "0"
    );

    const femalesRemoved = parseInt(
      penData?.Values?.find(
        (v) => v.Ref.toLowerCase() === "totalfemaleremoved"
      )?.Value?.toString() ?? "0"
    );

    return birdsalive - totaldead - malesRemoved - femalesRemoved;
  }

  function applyFunction(
    func: CustomLogicFuncParams,
    value: string | number
  ): string | number {
    if (isNullEmptyOrWhitespace(func.name)) return value;
    // console.log(`Applying function ${func.name}`);

    const args = [];

    if (func.args.length > 0) {
      for (const arg of func.args) {
        let newArg = arg?.toString();
        if (newArg.startsWith("$")) {
          const constructSyntax = "${" + newArg.substring(1) + "}";
          const paramLogic = parseCustomLogic(constructSyntax, data, params);

          newArg = paramLogic;
        }

        args.push(newArg);
      }
    }

    if (func.name === "dateadd") {
      const date = sqlDateObjectFromServerTZ(value);
      const offset = parseInt(args[0]?.toString() ?? "0");
      const unit = args[1] ?? "days";

      return localDateToSQL(dateAdd(date.normalised, offset, unit), {
        includeOffset: false,
      });
    }

    if (func.name === "datesubtract") {
      const date = sqlDateObjectFromServerTZ(value);
      const offset = parseInt(args[0]?.toString() ?? "0");
      const unit = args[1] ?? "days";

      return localDateToSQL(dateSubtract(date.normalised, offset, unit), {
        includeOffset: false,
      });
    }

    if (func.name === "datediff") {
      const date1String = value?.toString();
      const date2String = args[0]?.toString();
      let date1: Date;
      let date2: Date;

      // Handle time fields
      if (isTimeString(date1String)) {
        date1 = getDateFromTimeString(date1String);
      } else {
        date1 = localDateFromSQL(date1String);
      }
      if (isTimeString(date2String)) {
        date2 = getDateFromTimeString(date2String);
      } else {
        date2 = localDateFromSQL(date2String);
      }

      return dateDiffInMilliseconds(date1, date2);

      function extractHoursMinutesFromTimeString(dateString: string): {
        hours: number;
        minutes: number;
      } {
        if (isNullEmptyOrWhitespace(dateString))
          return { hours: 0, minutes: 0 };

        const hours = dateString?.slice(0, 2) ?? "0";
        const minutes = dateString?.slice(3, 5) ?? "0";

        return { hours: parseInt(hours), minutes: parseInt(minutes) };
      }

      function isTimeString(dateString: string) {
        return dateString.length === 5;
      }

      function getDateFromTimeString(dateString: string) {
        // Is time only, e.g. 04:00
        // Convert to date using current day
        const extractedTime = extractHoursMinutesFromTimeString(dateString);
        const startOfCurrentDateTime = startOfFromDate(localDate(), "day");
        let date: Date;

        date = dateAdd(startOfCurrentDateTime, extractedTime.hours, "hours");
        date = dateAdd(date, extractedTime.minutes, "minutes");

        return date;
      }
    }

    if (func.name === "cv") {
      const cov = calcCoefficiencyVariance([
        value,
        ...args.filter((v) => !isNullEmptyOrWhitespace(v)),
      ]);

      return cov;
    }

    if (func.name === "avg") {
      const avg = calcAverage([
        value,
        ...args.filter((v) => !isNullEmptyOrWhitespace(v)),
      ]);

      return avg;
    }

    if (func.name === "sd") {
      const sd = calcStandardDeviation([
        value,
        ...args.filter((v) => !isNullEmptyOrWhitespace(v)),
      ]);

      return sd;
    }

    return value;
  }
}

export function hasPendingFileSubmission(formValue: FormValue) {
  return (
    formValue.Value instanceof Object && formValue.Value?.pending?.length > 0
  );
}

export function createFormFieldLookupKey(ref: string, group: string | null) {
  const _refKey = ref.toLowerCase();
  const _questionGroupKey = group?.toLowerCase();
  const questionGroupKeySeparator = "|";

  return `${_refKey}${
    !isNullEmptyOrWhitespace(_questionGroupKey)
      ? `${questionGroupKeySeparator}${_questionGroupKey}`
      : ""
  }`;
}

export function createFormFieldLookup(
  formFields: FormField[]
): Map<string, FormField> {
  const formFieldLookup = new Map();
  formFields.forEach((ff) => {
    const key = createFormFieldLookupKey(ff.Ref, ff.QuestionGroup);
    formFieldLookup.set(key, ff);
  });
  return formFieldLookup;
}

// function getHouseBirdsAliveValue(
//   _formData: FormData,
//   property: string
// ): number {
//   // Birds alive
//   const birdsalive =
//     _formData.PenValues.reduce(
//       (result, pen) => (result += pen?.BirdsAlive?.BirdsAlive ?? 0),
//       0
//     ) ?? 0;

//   // Total dead
//   const totaldead =
//     _formData.PenValues?.reduce((result, pen) => {
//       const _totaldead =
//         pen.Values.find((v) => v.Ref.toLowerCase() === "totaldead")?.Value ??
//         "0";

//       return (result += parseInt(_totaldead));
//     }, 0) ?? 0;

//   // Birds removed
//   const birdsRemoved =
//     _formData.PenValues.reduce((result, pen) => {
//       const _malesRemoved =
//         pen.Values.find((v) => v.Ref.toLowerCase() === "totalmaleremoved")
//           ?.Value ?? "0";

//       const _femalesRemoved =
//         pen.Values.find((v) => v.Ref.toLowerCase() === "totalfemaleremoved")
//           ?.Value ?? "0";

//       return (result += parseInt(_malesRemoved) + parseInt(_femalesRemoved));
//     }, 0) ?? 0;

//   return birdsalive - totaldead - birdsRemoved;
// }

// function getPenBirdsAliveValue(
//   formData: FormData,
//   penId: string,
//   property: string
// ): number {
//   const penData = getPenDataFromFormData(penId, formData);
//   if (isNullEmptyOrWhitespace(penData)) return 0;

//   const birdsalive = penData?.BirdsAlive?.BirdsAlive ?? 0;

//   // Total dead
//   const totaldead = parseInt(
//     penData?.Values?.find((fv) => fv.Ref.toLowerCase() === "totaldead")
//       ?.Value ?? "0"
//   );

//   // Birds removed
//   const malesRemoved = parseInt(
//     penData?.Values?.find((v) => v.Ref.toLowerCase() === "totalmaleremoved")
//       ?.Value ?? "0"
//   );

//   const femalesRemoved = parseInt(
//     penData?.Values?.find((v) => v.Ref.toLowerCase() === "totalfemaleremoved")
//       ?.Value ?? "0"
//   );

//   return birdsalive - totaldead - malesRemoved - femalesRemoved;
// }
