import React, {
  useCallback,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import ReactDOM from "react-dom";
import {
  Breadcrumb as BreadcrumbCore,
  BreadcrumbItem,
} from "components/core/Breadcrumbs/Breadcrumb";
import { Modal } from "components/core";
import { useNavigate, useParams } from "react-router-dom";
import { AppDataContext } from "context/AppDataProvider";
import { NavContext } from "context/NavProvider";
import { isNullEmptyOrWhitespace, isNull, toReadableString } from "helpers/stringUtilities";
import useQuery from "hooks/useQuery";
import BreadcrumbItemMenu from "./BreadcrumbItemMenu";
import BreadcrumbItemFarm from "./BreadcrumbItemFarm";
import BreadcrumbItemHouse from "./BreadcrumbItemHouse";
import { redirectToListView } from "helpers/redirectUtilities";

const Breadcrumb = ({
  showHome = true,
  farmRequired,
  houseRequired,
  menuRequired = true,
  ...other
}) => {
  const query = useQuery();
  const farmId = query.get("farmId");
  const houseId = query.get("houseId");
  const menuId = query.get("menuId");
  const formId = query.get("formId");
  const { view } = useParams();

  const navigate = useNavigate();
  const { farms, menus } = useContext(AppDataContext);
  const { optionsToShow, setOptionsToHide, setOptionsToShow } =
    useContext(NavContext);
  const [farm, setFarm] = useState(undefined);
  const [house, setHouse] = useState(undefined);
  const [menu, setMenu] = useState(undefined);
  const [form, setForm] = useState(undefined);

  const navRef = useRef(undefined);
  const abortControllerRef = useRef(undefined);

  //#region Callbacks

  const handleOpenOption = (id) => {
    setOptionsToShow(id);
  };

  const handleOnShowOptions = (id, ev) => {
    setOptionsToShow(id);

    if (ev) {
      // Keep open menu in viewport
      ev.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  };

  const handleOnHideOptions = (id) => {
    setOptionsToHide(id);
  };

  const handleClickBreadcrumbRoot = () => {
    navigate({
      pathname: "/",
      search: null,
    });
  };

  const handleClickBreadcrumbHouse = useCallback(
    (houseId) => {
      const searchParams = new URLSearchParams(
        window.location.search.substring(1)
      );
      searchParams.set("houseId", houseId);
      navigate({
        search: "?" + searchParams.toString(),
      });
    },
    [navigate]
  );

  const handleClickForm = (menuId) => {

    // Redirect
    return navigate(redirectToListView(window.location, menuId));
  };

  const hasUnselectedRequirements = () => {
    return (
      (menuRequired && isNullEmptyOrWhitespace(menuId)) ||
      (farmRequired && isNullEmptyOrWhitespace(farmId)) ||
      (houseRequired && isNullEmptyOrWhitespace(houseId))
    );
  };

  const removeURLQuery = useCallback(
    (name) => {
      const searchParams = new URLSearchParams(
        window.location.search.substring(1)
      );
      searchParams.delete(name);
      navigate({
        search: "?" + searchParams.toString(),
      });
    },
    [navigate]
  );

  //#endregion

  //#region Side-effects

  /**
   * Mount/Unmount
   */
  useEffect(
    () => {
      abortControllerRef.current = new AbortController();

      return () => {
        abortControllerRef.current.abort();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  /**
   * Set menu from URL search param
   */
  useEffect(() => {
    if (!isNullEmptyOrWhitespace(menuId)) {
      const menu = menus.find((m) => {
        if (!isNullEmptyOrWhitespace(menuId)) {
          return m.MenuOption.toLowerCase() === menuId.toLowerCase();
        }

        return m.MenuOption.toLowerCase() === "production";
      });

      setMenu(menu);
    }
  }, [menuId, menus]);

  /**
   * Set farm
   */
  useEffect(() => {
    let farm = null;
    if (!isNull(farms) && !isNullEmptyOrWhitespace(farmId)) {
      farm = farms.find(
        (f) => f.FarmCode.toLowerCase() === farmId.toLowerCase()
      );
    }
    setFarm((prevState) => (prevState !== farm ? farm : prevState));
  }, [farmId, farms]);

  /**
   * Set house
   */
  useEffect(() => {
    let house = null;

    if (!isNull(farm) && !isNullEmptyOrWhitespace(houseId)) {
      house = farm.Houses.find(
        (h) => h.HouseNumber.toString() === houseId.toString()
      );
      if (isNull(house)) {
        // Remove houseId from URL
        removeURLQuery("houseId");
      }
    }
    setHouse((prevState) => (prevState !== house ? house : prevState));
  }, [farm, houseId, navigate, handleClickBreadcrumbHouse, removeURLQuery]);

  /**
   * Set options to show
   */
  useEffect(() => {
    const optionsToShow = (() => {
      if (farmRequired && !farmId) {
        // Farm
        return "farm";
      } else if (farm && houseRequired && isNullEmptyOrWhitespace(houseId)) {
        // House
        return "house";
      } else if (menuRequired && !menuId) {
        // Menu
        return "menu";
      }
      return false;
    })();
    setOptionsToShow(optionsToShow);
  }, [
    farmId,
    farmRequired,
    houseId,
    houseRequired,
    menuId,
    menuRequired,
    farm,
    setOptionsToShow,
  ]);

  /**
   * Set form
   */
  useEffect(() => {
    if (isNull(menu) || isNullEmptyOrWhitespace(formId)) return;

    const form = menu.Forms.find(
      (f) => f.FormName.toLowerCase() === formId.toLowerCase()
    );
    setForm(form);
  }, [formId, menu]);

  //#endregion

  //#region event handlers

  // const handleClickBreadcrumbForm = (formId) => {
  //   const searchParams = new URLSearchParams(location.search);
  //   searchParams.set("formId", formId);
  //   history.push({
  //     search: "?" + searchParams.toString(),
  //   });
  // };

  //#endregion

  const portalContainer = document.querySelector("#root");

  return (
    <BreadcrumbCore
      {...other}
      parentRef={navRef}
      onClickHome={handleClickBreadcrumbRoot}
      showHome={showHome}
    >
      {menuRequired && (
        <BreadcrumbItemMenu
          key="menu"
          menu={menu}
          farm={farm}
          menus={menus}
          showOptions={optionsToShow === "menu"}
          onShowOptions={(ev) => handleOnShowOptions("menu", ev)}
          onHideOptions={() => handleOnHideOptions("menu")}
        />
      )}
      {farmRequired && (
        <BreadcrumbItemFarm
          key="farm"
          farmId={farmId}
          menu={menu}
          farm={farm}
          farms={farms}
          showOptions={optionsToShow === "farm"}
          onShowOptions={(ev) => handleOnShowOptions("farm", ev)}
          onHideOptions={() => handleOnHideOptions("farm")}
        />
      )}
      {houseRequired && !isNullEmptyOrWhitespace(farm) && (
        <BreadcrumbItemHouse
          key="house"
          houseId={houseId}
          house={house}
          houses={farm.Houses}
          showOptions={optionsToShow === "house"}
          onShowOptions={(ev) => handleOnShowOptions("house", ev)}
          onHideOptions={() => handleOnHideOptions("house")}
        />
      )}
      {form && (
        <BreadcrumbItem
          key="form"
          title={form.FormTitle}
          subtitle={toReadableString(view)}
          showDivider={false}
          loaded={form !== undefined}
          onClick={() => handleClickForm(menuId)}
        />
      )}
      {hasUnselectedRequirements() &&
        portalContainer &&
        ReactDOM.createPortal(
          <Modal>
            {menuRequired && !menuId && (
              <p>
                Select a{" "}
                <span
                  className="text-primary cursor-pointer font-medium"
                  onClick={() => handleOpenOption("menu")}
                >
                  menu
                </span>{" "}
                to continue.
              </p>
            )}
            {farmRequired && !farmId && (
              <p>
                Select a{" "}
                <span
                  className="text-primary cursor-pointer font-medium"
                  onClick={() => handleOpenOption("farm")}
                >
                  farm
                </span>{" "}
                to continue.
              </p>
            )}
            {houseRequired && farmId && !houseId && (
              <p>
                Select a{" "}
                <span
                  className="text-primary cursor-pointer font-medium"
                  onClick={() => handleOpenOption("house")}
                >
                  house
                </span>{" "}
                to continue.
              </p>
            )}
          </Modal>,
          portalContainer
        )}
    </BreadcrumbCore>
  );
};

export default Breadcrumb;
