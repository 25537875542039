import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams, useLocation, Navigate } from "react-router-dom";
import {
  dateToString,
  startOfWeekFromDate,
  dateAdd,
  localDateFromUnix,
} from "helpers/dateUtilities";
import Fieldset from "components/forms/Fieldset";
import { FancyCounter } from "components/Animations";
import Form from "./Form";
import { isNull, isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { NotificationContext } from "context/NotificationProvider";
import { AppDataContext } from "context/AppDataProvider";
import { redirectToListView } from "helpers/redirectUtilities";
import { isNumeric } from "helpers/stringUtilities";
import useQuery from "hooks/useQuery";
import { LOADED_STATUS } from "constants.js";
import useDeepCompareEffect from "use-deep-compare-effect";
import { getPenDataFromFormData } from "helpers/formUtilities";

export default function Production(props) {
  const [loaded, setLoaded] = useState(LOADED_STATUS.LOADING);
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);

  const navigate = useNavigate();
  const location = useLocation();

  const query = useQuery();
  const menuId = query.get("menuId");
  const formId = query.get("formId");

  const { id: unixTimestamp } = useParams();

  const { addNotification } = useContext(NotificationContext);
  const { menus, pageSubtitle, setPageSubtitle } = useContext(AppDataContext);

  const {
    handleFormSubmit,
    dataStatus,
    farm,
    house,
    form,
    formValues,
    birdsAlive,
    birdAge = 0,
    formProps,
  } = props;

  //#region Callbacks

  //#endregion

  //#region Side-effects

  useEffect(() => {
    if (!isNull(farm)) {
      setLoaded(LOADED_STATUS.LOADED);
    }
  }, [farm]);

  // Set weekly schedule
  useDeepCompareEffect(() => {
    if (form?.FormName.toLowerCase() !== "weeklyproduction") return;

    const formDate = localDateFromUnix(unixTimestamp);

    const scheduledDayOfWeek = menus
      ?.find((m) => m.MenuOption.toLowerCase() === "production")
      ?.Forms?.find(
        (f) => f.FormName.toLowerCase() === "weeklyproduction"
      )?.Schedule;

    // Start date
    let newFromDate = startOfWeekFromDate(formDate, {
      offset: scheduledDayOfWeek - 1,
    });
    if (newFromDate.getTime() > formDate.getTime()) {
      // From date exceeds current schedule item date,
      // go back a week
      newFromDate = dateAdd(newFromDate, -1, "weeks");
    }
    setStartDate(newFromDate);

    // End date
    const newToDate = dateAdd(newFromDate, 6, "days");
    setEndDate(newToDate);

    if (newToDate && newFromDate) {
      setPageSubtitle(
        `${dateToString(newFromDate, {
          includeWeekday: true,
        })} - ${dateToString(newToDate, { includeWeekday: true })}`
      );
    }
  }, [unixTimestamp, menus, form?.FormName, setPageSubtitle, pageSubtitle]);

  //#endregion

  //#region Event handlers

  /**
   * Handle click cancel button
   */
  const handleClickCancel = () => {
    const searchParams = new URLSearchParams(
      window.location.search.substring(1)
    );
    searchParams.delete("formId");
    navigate({
      pathname: "/schedule",
      search: "?" + searchParams.toString(),
    });
  };

  //#endregion

  // Generate pen components
  const numPens = house?.Pens?.length;
  const pens = [];
  if (form?.FormName.toLowerCase() === "production") {
    const penFields = form?.FormFields.filter(
      (ff) => ff.Level.toLowerCase() === "p"
    );
    if (penFields.length > 0) {
      for (let index = 1; index <= numPens; index++) {
        const penFormValues = getPenDataFromFormData(index.toString(), formValues);
        pens.push(
          <Fieldset
            {...props}
            key={`fieldset-pen${index}`}
            title={`Pen ${index}`}
            text={`This section contains information regarding Pen ${index}.`}
            content={
              form?.FormName.toLowerCase() === "production" && (
                <PenStats
                  className="mt-4 mb-4"
                  stats={
                    !!house
                      ? [
                          {
                            name: "Birds Alive",
                            stat: penFormValues?.BirdsAlive?.BirdsAlive ?? 0,
                          },
                          {
                            name: "Mortality",
                            stat: (
                              <FancyCounter
                                value={
                                  penFormValues?.Values?.find(
                                    (fv) => fv.Ref.toLowerCase() === "totaldead"
                                  )?.Value ?? 0
                                }
                                duration={1000}
                              />
                            ),
                          },
                        ]
                      : []
                  }
                />
              )
            }
            penNumber={index}
            fields={penFields}
            formValues={getPenDataFromFormData(index.toString(), formValues)}
            collapsible
            startDate={startDate}
            endDate={endDate}
          />
        );
      }
    }
  }

  // TODO performance improvement, avoid running on ever rerender
  const pen1 = house?.Pens?.find((p) => p.PenNumber.toString() === "1");
  const totalBirdsPlaced =
    house?.Pens?.reduce(
      (result, pen) =>
        (result += pen.Placement?.BirdsPlaced
          ? parseInt(pen.Placement?.BirdsPlaced)
          : 0),
      0
    ) ?? 0;

  if (
    loaded === LOADED_STATUS.LOADED &&
    !isNullEmptyOrWhitespace(house) &&
    isNull(pen1.Placement?.BirdsPlaced)
  ) {
    // Production requires that house has placement to continue
    addNotification({
      title: "Error",
      theme: "error",
      description: `No placements found for ${farm.FarmName} house #${house.HouseNumber}.`,
    });

    return <Navigate to={redirectToListView(location, menuId, formId)} />;
  }

  return (
    <Form
      {...formProps}
      onClickCancel={handleClickCancel}
      onFormSubmit={handleFormSubmit}
      dataStatus={dataStatus}
      showDraft={[
        "production",
        "weeklyproduction",
        "monthlyproduction",
      ].includes(form?.FormName?.toLowerCase())}
      loaded={loaded}
    >
      <Fieldset
        {...props}
        key={`fieldset-house1`}
        title="House"
        text="This section contains general production info."
        content={
          form?.FormName.toLowerCase() === "production" && (
            <HouseStats
              className="mt-4 mb-4"
              stats={
                !!pen1
                  ? [
                      {
                        name: "Bird Age",
                        stat:
                          farm?.FarmGroup?.toLowerCase() === "bro"
                            ? birdAge.days
                            : birdAge.weeks,
                      },
                      {
                        name: "Placed Date",
                        stat:
                          dateToString(
                            pen1.Placement?._DatePlaced?.normalised
                          ) ?? "N/A",
                      },
                      {
                        name: "Birds Placed",
                        stat: totalBirdsPlaced,
                      },
                      {
                        name: "Birds Alive",
                        stat: birdsAlive ?? "N/A",
                      },
                    ]
                  : []
              }
            />
          )
        }
        penNumber="1"
        fields={form?.FormFields.filter((ff) => ff.Level.toLowerCase() === "h")}
        formValues={getPenDataFromFormData("1", formValues)}
        startDate={startDate}
        endDate={endDate}
      />
      {pens}
    </Form>
  );
}

function HouseStats({ stats, ...other }) {
  return (
    <div data-cy="house-stats" {...other}>
      <dl className="grid grid-cols-2 tablet:grid-cols-4 laptop:grid-cols-2 gap-2 tablet:divide-x laptop:divide-x-0">
        {stats.map((item) => (
          <div
            key={item.name}
            data-cy="stat"
            className="pl-2 py-2 tablet:pl-4 laptop:pl-2 overflow-hidden sm:p-6 flex flex-col-reverse"
            title={`${item.name} ${item.stat}`}
          >
            <dt className="text-xs tablet:text-sm text-gray-400 truncate">
              {item.name}
            </dt>
            <dd className="mt-1 mobile:text-xl tablet:text-base font-semibold text-gray-900">
              {isNumeric(item.stat) ? (
                <FancyCounter value={item.stat} duration={1000} />
              ) : (
                item.stat
              )}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
}

function PenStats({ stats, ...other }) {
  return (
    <div data-cy="pen-stats" {...other}>
      <dl className="grid grid-cols-3 gap-4 divide-x">
        {stats.map((item) => (
          <div
            key={item.name}
            data-cy="stat"
            className="pl-2 py-2 tablet:pl-4 laptop:pl-2 overflow-hidden sm:p-6 flex flex-col-reverse"
            title={`${item.name} ${item.stat}`}
          >
            <dt className="text-xs tablet:text-sm text-gray-400 truncate">
              {item.name}
            </dt>
            <dd className="mt-1 mobile:text-xl tablet:text-base font-semibold text-gray-900">
              {isNumeric(item.stat) ? (
                <FancyCounter value={item.stat} duration={1000} />
              ) : (
                item.stat
              )}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
}
