import { useContext, useEffect, useState } from "react";
import Form from "./Form";
import { useNavigate, useParams } from "react-router-dom";
import Fieldset from "components/forms/Fieldset";
import { AppDataContext } from "context/AppDataProvider";
import { dateAdd, endOfFromDate, startOfFromDate } from "helpers/dateUtilities";
import { getPenDataFromFormData } from "helpers/formUtilities";

/**
 * Component to render event fieldset.
 * @param {object} props
 * @returns {JSX} Returns JSX to render a House fieldset.
 */
export default function Schedule(props) {
  const {
    handleFormSubmit,
    dataStatus,
    house,
    form,
    formValues,
    loaded,
    formProps,
    farm,
  } = props;
  const navigate = useNavigate();
  const { id } = useParams();
  const { schedules } = useContext(AppDataContext);

  const [schedule, setSchedule] = useState(undefined);

  //#region Callbacks

  /**
   * Get the form start date
   * @returns {Date}  The start date
   */
  const getStartDate = () => {
    if (!schedule?.StartDays || !house) return null;

    const placement = getPlacement(house);
    const dateHatched = placement?._HatchDate?.normalised;

    const newDate = dateAdd(dateHatched, schedule.StartDays, "days");

    return startOfFromDate(newDate, "day");
  };

  /**
   * Get the form end date
   * @returns {Date}  The end date
   */
  const getEndDate = () => {
    if (!schedule?.EndDays || !house) return null;

    const placement = getPlacement(house);
    const dateHatched = placement?._HatchDate?.normalised

    const newDate = dateAdd(dateHatched, schedule.EndDays, "days");

    return endOfFromDate(newDate, "day");
  };

  //#endregion

  //#region Side-effects

  /**
   * Set schedule
   */
  useEffect(() => {
    if (!id || !schedules?.length) return;

    setSchedule(
      schedules.find((s) => s.FarmScheduleID.toString() === id.toString())
    );
  }, [id, schedules]);

  //#endregion

  //#region Event handlers

  /**
   * Handle click cancel button
   */
  const handleClickCancel = () => {
    const searchParams = new URLSearchParams(
      window.location.search.substring(1)
    );
    searchParams.delete("formId");
    navigate({
      pathname: "/schedule",
      search: "?" + searchParams.toString(),
    });
  };

  //#endregion

  return (
    <Form
      {...formProps}
      onClickCancel={handleClickCancel}
      onFormSubmit={(ev) => {
        ev.preventDefault();
        handleFormSubmit(ev, { entryId: schedule.FarmScheduleID });
      }}
      dataStatus={dataStatus}
      loaded={loaded}
    >
      <Fieldset
        {...props}
        key={`fieldset-house1`}
        title={schedule?.Description}
        text="This section contains scheduled event info."
        penNumber="1"
        fields={form?.FormFields.filter((ff) => ff.Level.toLowerCase() === "h")}
        formValues={getPenDataFromFormData("1", formValues)}
        startDate={getStartDate()}
        endDate={getEndDate()}
        farm={farm}
      />
    </Form>
  );
}

function getPlacement(house) {
  if (!house?.Pens?.length) return null;

  return house.Pens.find((p) => p.PenNumber.toString() === "1").Placement;
}
