import React, { useContext } from "react";
import { NavContext } from "context/NavProvider";

export default function PageNotFound({ selectedFarm, selectedHouse }) {
  const { setOptionsToShow } = useContext(NavContext);

  return (
    <div>
      <p>
        Page not found for selected farm <strong>{selectedFarm}</strong>, house{" "}
        <strong>#{selectedHouse}</strong>. Try selecting a different{" "}
        <span
          className="text-primary cursor-pointer"
          onClick={() => setOptionsToShow("farm")}
        >
          Farm
        </span>, <span
          className="text-primary cursor-pointer"
          onClick={() => setOptionsToShow("house")}
        >
          House
        </span>{" "}
        to continue.
      </p>
    </div>
  );
}
