import React, { useState, useEffect, useCallback } from "react";
import { PropTypes } from "prop-types";
import { Label } from "components/core";
import Addon from "./Addon";
import { ExclamationCircleIcon } from "assets/icons";
import { SelectOption } from "./SelectOption";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import useCalcTrigger from "hooks/useCalcTrigger";

const Select = ({
  label,
  id,
  hint,
  validate,
  value,
  setValue,
  setValid,
  required = true,
  isFullWidth = false,
  disabled = false,
  labelPosition = "top",
  labelSize,
  addonLeft,
  addonRight,
  dependencies = [],
  theme,
  defaultValue,
  render,
  placeholder = "- Select - ",
  listOptions,
  disableCalcTrigger,
  ...other
}) => {
  useCalcTrigger(value, setValue, disableCalcTrigger);

  const [touched, setTouched] = useState(false);
  const [error, setError] = useState(false);
  const [focused, setFocused] = useState(false);
  const [hasValue, setHasValue] = useState(value?.length > 0);

  const inputErrorClasses = ["border-danger-600"];
  const inputClasses = [
    "rounded-md pt-3 pb-3",
    "border-1",
    "focus:ring-4 focus:ring-offset-0",
    `${disabled ? "disabled:opacity-50" : ""}`,
  ];
  const errorIconClasses = [];

  // Addons
  if (addonLeft) {
    inputClasses.push("pl-10");
  }
  if (addonRight) {
    inputClasses.push("pr-10");
  }

  // Theme
  if (theme === "darkgray") {
    inputClasses.push(
      "bg-gray-900 border-gray-500 text-gray-400 focus:border-gray-400 focus:ring-gray-800"
    );
    errorIconClasses.push("bg-gray-900");
  } else {
    inputClasses.push(
      "bg-white border-gray-300 focus:border-gray-700 focus:ring-gray-50"
    );
    errorIconClasses.push("bg-white");
  }

  //#region Callbacks

  /**
   * Set the input value.
   * @param {string} value  The new input value.
   */
  const setInputValue = useCallback((value) => {
    // Prevent setting input to null or undefined
    // Controlled components should not have null or undefined value
    if (typeof value === "undefined" || value === null) return;

    setValue(value);
    setHasValue(value.length > 0);
  }, [setValue]);

  /**
   * Validate the input value.
   * @returns {string|null} The error message or null if valid.
   */
  const validation = () => {
    if (validate) return validate(value);
  };

  //#endregion

  //#region Side-effects

  /**
   * Default value
   * Set default value here to trigger calculation parser,
   * otherwise calculation strings won't be evaluated.
   */
  useEffect(() => {
    if (touched || !defaultValue || !setInputValue) return;

    if (isNullEmptyOrWhitespace(value)) {
      setInputValue(defaultValue);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Trigger validation when value or dependencies change.
   * Useful when you wish to revalidate when related input changes.
   */
   useEffect(() => {
    const validationResult = validation();
    if (validationResult !== error) setError(validationResult);
    if (setValid) setValid(!validationResult, value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies, value]);

  //#endregion

  // Prevent dom element rendering
  if (render === false) {
    return null;
  }

  return (
    <div
      className={`${
        labelPosition === "left" ? "grid grid-cols-3 gap-4" : "relative"
      }`}
    >
      <Label
        id={id}
        text={label}
        required={required}
        focused={focused}
        hasValue={hasValue}
        position={labelPosition}
        size={labelSize}
        theme={theme}
        erroneous={touched && error && !focused}
      />
      <div className={`mt-1 ${labelPosition === "left" ? "col-span-2" : ""}`}>
        <div className="rounded-md shadow-sm relative">
          {addonLeft && <Addon position="left">{addonLeft}</Addon>}
          <select
            id={id}
            className={`block w-full tablet:text-sm ${inputClasses.join(" ")} ${
              touched && error && !focused ? inputErrorClasses.join(" ") : ""
            }`}
            value={value ?? ""}
            onBlur={() => {
              setTouched(true);
              setFocused(false);
            }}
            onChange={(ev) => setInputValue(ev.target.value)}
            onFocus={() => setFocused(true)}
            aria-invalid={error}
            disabled={disabled}
            {...other}
          >
            {(!value || !listOptions?.length || listOptions.some((option) => option.Value?.toLowerCase() === value?.toLowerCase()) === false) && <SelectOption value="">{placeholder}</SelectOption>}
            {listOptions?.map((option) => (
              <SelectOption key={option.Value} value={option.Value}>{option.Text}</SelectOption>
            ))}
          </select>
          {addonRight && <Addon position="right">{addonRight}</Addon>}
          {touched && error && !focused && (
            <div className="absolute -top-2 right-2 pointer-events-none bg-white px-1">
              <ExclamationCircleIcon className="h-5 w-5 text-danger-500" />
            </div>
          )}
        </div>
        {touched && error && !focused ? (
          <p className="mt-2 text-xs text-danger-600" id="email-error">
            {error}
          </p>
        ) : (
          hint && (
            <p className="mt-2 text-xs text-gray-500" id="email-error">
              {hint}
            </p>
          )
        )}
      </div>
    </div>
  );
};

Select.propTypes = {
  label: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hint: PropTypes.string,
  validate: PropTypes.func,
  type: PropTypes.oneOf([
    "text",
    "email",
    "password",
    "number",
    "url",
    "date",
    "datetime-local",
    "month",
    "week",
    "time",
    "search",
    "tel",
    "checkbox",
    "radio",
  ]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setValue: PropTypes.func,
  setValid: PropTypes.func,
  labelPosition: PropTypes.string,
  labelSize: PropTypes.oneOf(["large", "small", undefined]),
  addonLeft: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  addonRight: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  dependencies: PropTypes.array,
  children: PropTypes.arrayOf(PropTypes.node),
  render: PropTypes.bool,
};

export { Select };
