import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { isNull } from "helpers/stringUtilities";
import { ChevronRightIcon } from "assets/icons";
import classNames from "classnames";

const GroupedFields = ({
  showDivider = true,
  className = "",
  children,
  render,
  enableCollapsible = true,
  ...other
}) => {
  const [open, setOpen] = useState(children?.length === 1 ? true : false);
  // const [elements, setElements] = useState(undefined);
  // [{ Ref: 123, Collapsed: false }, { Ref: 321, Collapsed: true }]
  const [collapsibleElements, setCollapsibleElements] = useState(undefined);

  //#region Callbacks

  const handleClickOpen = () => {
    if (!enableCollapsible || !setOpen) return;

    setOpen(true);
    setCollapsibleElements((prevState) => {
      const _newState = [...prevState];
      _newState.forEach((r) => {
        r.collapsed = false;
      });

      return _newState;
    });
    // collapseFields(false);
  };

  const handleClickClose = () => {
    if (!enableCollapsible || !setOpen) return;

    setOpen(false);
    setCollapsibleElements((prevState) => {
      const _newState = [...prevState];
      _newState.forEach((r) => {
        r.collapsed = true;
      });

      return _newState;
    });
    // collapseFields(true);
  };

  const handleIsCollapsible = useCallback(
    (ref, collapsed) => {
      const _default = true; // Collapsed
      setCollapsibleElements((prevState) => {
        const newState = !isNull(prevState) ? [...prevState] : [];

        const foundIndex = prevState?.findIndex((r) => r.ref === ref);
        let _collapsed = collapsed ?? _default;
        if (foundIndex !== undefined && foundIndex !== -1) {
          // replace existing
          newState.splice(foundIndex, 1, { ref, collapsed: _collapsed });
        } else {
          // add new
          newState.push({ ref, collapsed: _collapsed });
        }
        setOpen(!_collapsed); // If any are expanded then open === true
        return newState;
      });
    },
    [setCollapsibleElements]
  );

  //#endregion

  //#region Render helpers

  const hasCollasibleElements =
    !isNull(collapsibleElements) && Object.keys(collapsibleElements).length > 0;

  const elements = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      const collapsibleElement = collapsibleElements?.find(
        (r) => r.ref === child.props.field?.Ref
      );
      return React.cloneElement(child, {
        setIsCollapsible: enableCollapsible && handleIsCollapsible,
        collapsed:
          enableCollapsible && !isNull(collapsibleElement)
            ? collapsibleElement.collapsed
            : undefined,
      });
    }
    return child;
  });

  //#endregion

  if (children?.length === 0) return null;

  if (!!showDivider) {
    className += "border-t pt-8";
  }

  return (
    <div
      {...other}
      className={classNames(
        render === false ? "hidden" : "",
        `${className} grouped-field col-span-4 space-y-4`
      )}
    >
      <div className={classNames("relative space-y-4", hasCollasibleElements && "pl-6")}>
        {render !== false && hasCollasibleElements && (
          <div className="absolute top-0 bottom-0 left-0">
            <div
              className="h-full w-6 text-primary cursor-pointer absolute -left-3"
              title={open ? "Collapse" : "Expand"}
              onClick={!open ? handleClickOpen : handleClickClose}
            >
              <ChevronRightIcon
                className={`w-6 h-6 absolute top-0 left-0 transition-transform transform duration-500 ease-in-out ${
                  !open ? "rotate-0" : "rotate-90"
                }`}
              />
            </div>
          </div>
        )}
        {elements}
      </div>
    </div>
  );
};

GroupedFields.propTypes = {
  showDivider: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any,
  render: PropTypes.bool,
};

export default GroupedFields;
