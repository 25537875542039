import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { dateToString } from "helpers/dateUtilities";
import { ListItem } from "components/core";
import ListItemIcon from "components/Schedule/ListItemIcon";
import ListItemStatus from "components/Schedule/ListItemStatus";

export default function ListItemWeekly({
  entry,
  date: scheduleItemDate,
  dateString,
  dateToday,
  toDate,
  fromDate,
  farmId,
  houseId,
  formId,
  loaded = true,
  className,
  ...other
}) {
  let navigate = useNavigate();

  const [classes, setClasses] = useState([className]);

  //#region Callbacks

  /**
   * Handle list item click
   */
  const handleClick = (timestamp, formId) => {
    const searchParams = new URLSearchParams(
      window.location.search.substring(1)
    );
    searchParams.set("formId", formId);
    navigate({
      pathname: `/forms/edit/${timestamp}`,
      search: "?" + searchParams.toString(),
    });
  };

  //#endregion

  //#region Side-effects

  /**
   * Build classes
   */
  useEffect(() => {
    setClasses([className, !loaded ? "animate-pulse" : ""]);
  }, [loaded, className]);

  //#endregion

  if (!loaded) {
    return (
      <ListItem className="animate-pulse" aria-label="daily">
        <div className="h-10 w-10 mr-2 rounded-full bg-gray-300"></div>
        <div className="flex-grow">
          <div className="mb-0.5 h-4 w-1/4 bg-gray-300 rounded"></div>
          <div className="text-xs text-gray-500">
            <div className="flex">
              <div className="h-2 w-1/2 bg-gray-300 rounded"></div>
            </div>
          </div>
        </div>
      </ListItem>
    );
  }

  return (
    <ListItem
      aria-label="weekly"
      className={classes.join(" ")}
      onClick={() =>
        handleClick(entry?._DateApplies?.normalised?.getTime() ?? scheduleItemDate.getTime(), "weeklyproduction")
      }
      {...other}
    >
      <ListItemIcon
        dataStatus={entry?.Status}
        date={toDate}
        dateToday={dateToday}
        loaded={entry !== undefined}
      />
      <div className="flex-grow">
        <div className="font-medium">Weekly</div>
        <div className="text-sm text-gray-900" data-cy="date-range">
          {fromDate && `${dateToString(fromDate, { includeWeekday: true })} -`}{" "}
          {toDate && `${dateToString(toDate, { includeWeekday: true })}`}
        </div>
        <div className="text-sm">
          <div className="flex">
            <ListItemStatus
              dataStatus={entry?.Status}
              date={scheduleItemDate}
              dateToday={dateToday}
              loaded={entry !== undefined}
            />
          </div>
        </div>
        {entry && entry._LastModified && (
          <div className="text-xs text-gray-400">
            Updated:{" "}
            {dateToString(entry._LastModified.localised, {
              includeTime: true,
            })}
          </div>
        )}
      </div>
    </ListItem>
  );
}
