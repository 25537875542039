import { isNullEmptyOrWhitespace } from "./stringUtilities";
import { round, roundNaturally } from "helpers/mathUtilities";
import jsonLogic from "json-logic-js";
import { dateToString } from "./dateUtilities";
import { getPenDataFromFormData, parseCustomLogic } from "./formUtilities";
import { getFarmHousePen } from "./farmUtilities";
import { deepClone } from "./dataUtilities";

/**
 * Recursively build form values.
 * @param {object} field
 * @param {string|number} pen
 * @param {string|number} value
 * @returns {[{ Ref: string, Value: string|number, Pen: string|number }]}
 */
export function buildFormValues(
  field,
  penId,
  value,
  form,
  menuId,
  dataSources,
  farm,
  house,
  birdAge,
  formDate,
  previouslyChanged // Store changed dependencies to avoid infinite loop
) {
  const thisFormValues = dataSources?.this ?? null;
  const prevFormValues = dataSources?.previous ?? null;
  // console.count(`buildFormValues:${field.Ref}`);

  // Value already set, prevent infinite loop
  // const existingFormValue = getExistingFormValue();
  // console.log("existingFormValue", field.Ref, existingFormValue === value);
  // if (existingFormValue === value) return thisFormValues; // Do nothing
  // console.log("existingFormValue", field.Ref, existingFormValue);

  let newFormValues = deepClone(thisFormValues);

  if (!previouslyChanged) previouslyChanged = new Set();

  // Avoid infinite loop
  previouslyChanged.add(
    `${field.Ref}${
      isNullEmptyOrWhitespace(field.QuestionGroup)
        ? ""
        : `|${field.QuestionGroup}`
    }`
  );

  // Format form values to pass to jsonLogic
  const penValuesAsObj = formValuesToObject(thisFormValues?.PenValues);
  const prevFormValuesAsObj = formValuesToObject(prevFormValues?.PenValues);

  // Parse value for calculations
  // test for JSON conditional logic
  const result = parseJsonLogic(value, {
    waterMeterType: house.WaterMeterType,
    this: value,
    previous: prevFormValuesAsObj,
    ...penValuesAsObj,
  });

  const isCalculation = /\${(?!date)(?:[^{}]+)}/i.test(result);
  // const parsedValue = parseCalculationString(
  //   result,
  //   newFormValues,
  //   prevFormValues,
  //   standards,
  //   farm,
  //   house,
  //   penId,
  //   birdAge,
  //   formDate
  // );
  const thisFarmHouse = getFarmHousePen(house, penId);
  const params = {
    PenId: penId?.toString(),
    GroupId: field.QuestionGroup,
    BirdType: thisFarmHouse?.BirdType ?? null,
    BirdSex: thisFarmHouse?.BirdSex ?? null,
    BirdAge: { Days: birdAge ?? null, Weeks: null },
    FarmGroup: farm?.FarmGroup ?? null,
  };
  let parsedValue = "";
  if (!isNullEmptyOrWhitespace(result)) {
    parsedValue = parseCustomLogic(result, dataSources, params);
  }
  let evalValue = parsedValue;
  // Uncomment to debug
  // prettier-ignore
  // console.log("buildFormValues", "Ref:", field.Ref, "Group:", field.QuestionGroup, "calculation:", result, "result:", evalValue, "dataSources:", dataSources);

  if (isCalculation) {
    try {
      // eslint-disable-next-line no-eval
      evalValue = eval(parsedValue);
    } catch (err) {
      evalValue = parsedValue;
    }
  }

  // Format value
  // Form field doesn't exist we just completely ignore...
  if (!field?.FieldType) return newFormValues;

  evalValue = formatValue(evalValue, field.FieldType);
  // prettier-ignore
  // console.log("buildFormValues", "Ref:", field.Ref, "Group:", field.QuestionGroup, "result:", evalValue);

  // additional form related properties
  const other = {};

  if (field?.QuestionGroup) {
    // QuestionGroup
    other.QuestionGroup = field.QuestionGroup;
  }

  // Map additional listOption properties to formValue
  const listOption = field.ListOptions?.find(
    (lo) => evalValue?.toString() === lo.Value?.toString()
  );

  if (listOption !== undefined) {
    const _score = listOption?.Score;
    if (_score) other.Score = _score;

    const _text = listOption?.Text;
    if (_text) other.Text = _text;

    const _days = listOption?.Days;
    if (_days) other.Days = _days;
  }

  const pen = getPenDataFromFormData(penId.toString(), newFormValues);
  if (!isNullEmptyOrWhitespace(evalValue) && pen === undefined) {
    // Only add if contains a value
    // Pen doesn't exist in formValues... add it
    if (newFormValues?.PenValues === undefined) {
      newFormValues.PenValues = [];
    }

    // New pen
    newFormValues.PenValues.push({
      Pen: penId,
      Values: [
        {
          Ref: field.Ref,
          Value: evalValue,
          ...other,
        },
      ],
    });
  } else if (pen !== undefined) {
    // Existing pen
    // Remove existing value from formValues array
    const newValues = pen.Values.filter(
      (fv) =>
        !(
          (fv.Ref === field.Ref &&
            isNullEmptyOrWhitespace(field.QuestionGroup)) ||
          (fv.Ref === field.Ref && fv.QuestionGroup === field.QuestionGroup)
        )
    );

    if (!isNullEmptyOrWhitespace(evalValue)) {
      // Only add if contains a value
      // Add new value
      newValues.push({
        Ref: field.Ref,
        // eslint-disable-next-line no-eval
        Value: evalValue,
        ...other,
      });
    }

    pen.Values = newValues;
  }

  // Calculations
  // find all form fields that contain this ref in their Calculation
  /// and add to list of dependencies
  const calcDependencies = form.FormFields.filter((ff) => {
    const funcPattern = new RegExp(
      // eslint-disable-next-line no-template-curly-in-string
      "\\${(?:cv|avg|sd|datediff):([^{}]*" + field.Ref + "[^{}]*)}",
      "i"
    );

    const formValuePattern = new RegExp(
      // eslint-disable-next-line no-template-curly-in-string
      "\\${(?:[a-z0-9]+:)*(?:" + field.Ref + "(?:.[a-z0-9]+)?)}",
      "i"
    );

    return (
      (formValuePattern.test(ff.Calculation) ||
        funcPattern.test(ff.Calculation) || // Coefficient of Variance, Average & Standard Deviation
        ff.Calculation?.includes("house:birdsalive") ||
        ff.Calculation?.includes("pen:birdsalive")) &&
      !(
        ff.Ref === field.Ref &&
        (isNullEmptyOrWhitespace(field.QuestionGroup) ||
          ff.QuestionGroup === field.QuestionGroup)
      ) && // Avoid changing itself
      !previouslyChanged.has(
        `${ff.Ref}${
          isNullEmptyOrWhitespace(ff.QuestionGroup)
            ? ""
            : `|${ff.QuestionGroup}`
        }`
      ) // avoid infinite loop
    );
  });

  calcDependencies.forEach((cd) => {
    // cd.Calculation could be a JSON object or string
    let calculations;
    try {
      // JSON string
      calculations = JSON.parse(cd.Calculation);
    } catch {
      // String
      calculations = cd.Calculation;
    }

    // Ensure it's an array
    if (!(calculations instanceof Array)) calculations = [calculations];

    // Iterate through form field calculations array
    calculations.forEach((calculation) => {
      // Now replace calc dependency values with eval(parseCalc)
      newFormValues = buildFormValues(
        cd,
        cd.Level.toLowerCase() === "h" ? 1 : penId,
        // eslint-disable-next-line no-eval
        calculation,
        form,
        menuId,
        {
          ...dataSources,
          this: newFormValues,
        },
        farm,
        house,
        birdAge,
        formDate,
        previouslyChanged
      );
    });
  });

  return newFormValues;

  // function getExistingFormValue() {
  //   return getPenDataFromFormData(
  //     penId.toString(),
  //     thisFormValues
  //   )?.Values?.find(
  //     (fv) =>
  //       (fv.Ref === field.Ref &&
  //         isNullEmptyOrWhitespace(field.QuestionGroup)) ||
  //       (fv.Ref === field.Ref && fv.QuestionGroup === field.QuestionGroup)
  //   )?.Value;
  // }
}

/**
 * Convert form values to an Object.
 * @param {[{ Pen: String|Number, Values: [{ Ref: String, Value: String|Number }]}]} formValues
 * @returns {{ String: { String: { Ref: string, Value: String|Number } } }}
 */
export function formValuesToObject(formValues) {
  if (isNullEmptyOrWhitespace(formValues)) return {};

  return formValues.reduce((result, pen) => {
    result[`pen${pen.Pen}`] = pen.Values.reduce((result2, pv) => {
      result2[pv.Ref] = pv;

      return result2;
    }, {});

    return result;
  }, {});
}

/**
 * Parse JSON logic
 * @param {*} value
 * @param {*} data
 * @returns
 */
export function parseJsonLogic(value, data) {
  return jsonLogic.apply(value, { ...data });
}

/**
 * Format value
 * @param {*} value
 * @param {*} fieldType
 * @returns
 */
const formatValue = (value, fieldType) => {
  if (value === null || value === undefined || !value?.toString()?.length)
    return value;

  // Calculated fields are deliberately not rounded up to prevent changing DB values unintentionally.
  fieldType = fieldType?.toLowerCase();
  switch (fieldType) {
    case "i":
      value = roundNaturally(value, 0);
      break;
    case "cfr":
      value = round(value);
      break;
    case "fl":
      value = roundNaturally(value, 3);
      break;
    default:
      value = roundNaturally(value, 2);
      break;
  }

  return value;
};

export function getReadonlyFormValueByFieldType(field, formValue) {
  if (isNullEmptyOrWhitespace(formValue)) return formValue;

  const _fieldType = field.FieldType?.toLowerCase();
  // Here we decide how to render the parent fields
  // Change field type of current fields to 'tx'
  switch (_fieldType) {
    case "dp": // Datepicker
    case "rdp": // Restricted Datetimepicker
      return dateToString(formValue.Value, { includeTime: false });
    case "dtp": // Datetimepicker
      return dateToString(formValue.Value, { includeTime: true });
    case "ac": // Autocomplete
    case "up": // Upload
      return formValue.Value?.split(",");
    case "cb": // Checkbox
    case "cf": // Calculated field
    case "cfr": // Calculated field
    case "mdd": // Multi-select Dropdown
    case "dd": // Dropdown
    case "bg": // Button Group
      const _text = field.ListOptions?.find(
        (o) => o.Value === formValue.Value
      )?.Text;
      return _text ? _text : formValue.Value;
    case "sl": // Comment
    case "t":
    case "tx": // HTML text
    case "f": // Float
    case "i": // Integer
    default:
      return formValue.Value ?? "";
  }
}

export function getPWAIDfromFormValuesRecord(record) {
  if (record === undefined || record.PenValues === undefined) return undefined;

  // We currently assume that pen 1 always exists and has the pwa id
  const pen1 = record.PenValues.find((pv) => pv.Pen.toString() === "1");
  if (pen1 === undefined) {
    return undefined;
  }

  const pwaid = pen1.Values?.find(
    (pv) => pv.Ref.toLowerCase() === "pwaid"
  )?.Value;

  return pwaid;
}
