import { PencilIcon } from "assets/icons";
import classNames from "classnames";
import { Button, Modal, MultiSelect } from "components/core";
import { CHART_TYPE } from "constants.js";
import { useState } from "react";

export default function AddMetricButton({
  chartType,
  onAddMetrics,
  className,
  metrics,
  selectedMetrics: selectedMetricsProp,
  ...other
}) {
  const [open, setOpen] = useState(false);
  const [selectedMetrics, setSelectedMetrics] = useState(selectedMetricsProp);

  function handleClickAddMetric() {
    setOpen(true);
  }

  function handleSaveMetrics() {
    onAddMetrics(selectedMetrics.split(","));
    setOpen(false);
  }

  return (
    <>
      <Button
        className={classNames(className)}
        size="small"
        type="button"
        onClick={handleClickAddMetric}
      >
        <PencilIcon className="w-4 h-4 text-gray-400" />
      </Button>
      <Modal
        title="Add metric"
        open={open}
        onOpen={(_open) => setOpen(_open)}
        onSave={() => handleSaveMetrics()}
      >
        <div className="py-2">
          <MultiSelect
            id="metric-select"
            label="Metrics"
            labelPosition="inset"
            setValue={setSelectedMetrics}
            value={selectedMetrics}
            listOptions={metrics.map((item) => ({
              Id: item.id,
              // Text: item.title,
              Text: (
                <div className="text-left">
                  <div>{item.title}</div>
                  {item.dataSource && (
                    <div className="text-xs opacity-70">{item.dataSource}</div>
                  )}
                </div>
              ),
              Value: item.id,
            }))}
            showSearch={true}
            sortable={true}
            limit={
              [CHART_TYPE.METRIC, CHART_TYPE.TREND].includes(chartType) ? 1 : 0
            }
            disableCalcTrigger={true}
          />
        </div>
      </Modal>
    </>
  );
}
