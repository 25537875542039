import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  dateFromDayOfYear,
  dateToString,
  startOfFromDate,
} from "helpers/dateUtilities";
import { ListItem } from "components/core";
import ListItemIcon from "components/Schedule/ListItemIcon";
import ListItemStatus from "components/Schedule/ListItemStatus";
import { isNull, isNullEmptyOrWhitespace } from "helpers/stringUtilities";

export default function ListItemMonthly({
  todayEntries,
  event,
  date,
  dateToday,
  dateHatched,
  farmId,
  houseId,
  loaded = true,
  className,
  ...other
}) {
  let navigate = useNavigate();
  const [fromDate, setFromDate] = useState(undefined);
  const [toDate, setToDate] = useState(undefined);
  const [classes, setClasses] = useState([className]);
  const [formId, setFormId] = useState(undefined);
  const [entry, setEntry] = useState(undefined);

  //#region Side-effects

  /**
   * Set entry
   */
  useEffect(() => {
    if (isNull(todayEntries) || isNullEmptyOrWhitespace(formId)) return;

    const _newEntry = todayEntries.find(
      (e) => e.FormName.toLowerCase() === formId
    );
    setEntry(_newEntry ?? null);
  }, [todayEntries, formId]);

  /**
   * Set To & From dates
   */
  useEffect(() => {
    if (isNullEmptyOrWhitespace(event) || isNull(date)) return;

    const { StartDays, EndDays } = event;

    // By calendar days of year
    // Start date
    const newFromDate = dateFromDayOfYear(StartDays, {
      year: date.getFullYear(),
    });
    setFromDate(newFromDate);
    // End date
    const newToDate = dateFromDayOfYear(EndDays, { year: date.getFullYear() });
    setToDate(newToDate);
  }, [event, date]);

  /**
   * Set form Id
   */
  useEffect(() => {
    if (!event?.Type) return;

    switch (event.Type.toLowerCase()) {
      case "m":
        setFormId("monthlyproduction");
        break;
      default:
        setFormId(null);
        break;
    }
  }, [event?.Type]);

  /**
   * Build classes
   */
  useEffect(() => {
    setClasses([className, !loaded ? "animate-pulse" : ""]);
  }, [loaded, className]);

  //#endregion

  //#region Event handlers

  /**
   * Handle list item click
   */
  const handleClick = (recordId, formId) => {
    const searchParams = new URLSearchParams(
      window.location.search.substring(1)
    );
    searchParams.set("formId", formId);
    navigate({
      pathname: `/forms/edit/${recordId}`,
      search: "?" + searchParams.toString(),
    });
  };

  //#endregion

  //#region Helpers

  /**
   * Should the list item be shown
   * @returns {boolean} True/false the list item should show.
   */
  const shouldShow = () => {
    const completedDate = event?._CompletedDate;
    // Completed event
    if (completedDate && completedDate.normalised.getTime() === date?.getTime())
      return true;

    // Event list date is the past && toDate is valid in future, don't render
    if (
      !completedDate &&
      date?.getTime() < startOfFromDate(dateToday, "day").getTime() &&
      toDate?.getTime() > date?.getTime()
    )
      return false;

    // Event list date is between from & to date, render
    if (
      !completedDate &&
      (date?.getTime() >= fromDate?.getTime() ||
        date?.getTime() <= toDate?.getTime())
    )
      return true;

    return false;
  };

  //#endregion

  if (!loaded) {
    return (
      <ListItem className="animate-pulse" aria-label="daily">
        <div className="h-10 w-10 mr-2 rounded-full bg-gray-300"></div>
        <div className="flex-grow">
          <div className="mb-0.5 h-4 w-1/4 bg-gray-300 rounded"></div>
          <div className="text-xs text-gray-500">
            <div className="flex">
              <div className="h-2 w-1/2 bg-gray-300 rounded"></div>
            </div>
          </div>
        </div>
      </ListItem>
    );
  }

  return shouldShow() ? (
    <ListItem
      aria-label="monthly"
      className={classes.join(" ")}
      onClick={() =>
        handleClick(event.FarmScheduleID || date.getTime(), "monthlyproduction")
      }
      {...other}
    >
      <ListItemIcon
        dataStatus={entry?.Status}
        date={date}
        dateToday={dateToday}
        loaded={entry !== undefined}
      />
      <div className="flex-grow">
        <div className="font-medium">Monthly</div>
        <div className="text-sm text-gray-900" data-cy="date-range">
          {fromDate && `${dateToString(fromDate, { includeWeekday: true })} -`}{" "}
          {toDate && `${dateToString(toDate, { includeWeekday: true })}`}
        </div>
        <div className="text-sm">
          <div className="flex">
            <ListItemStatus
              dataStatus={entry?.Status}
              date={date}
              dateToday={dateToday}
              loaded={entry !== undefined}
            />
          </div>
        </div>
        {entry && entry._LastModified && (
          <div className="text-xs text-gray-400">
            Updated:{" "}
            {dateToString(entry._LastModified.localised, {
              includeTime: true,
            })}
          </div>
        )}
      </div>
    </ListItem>
  ) : null;
}
