import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Alert,
  Button,
  Datepicker,
  MultiSelect,
  TableChart,
} from "components/core";
import PageHeader from "components/PageHeader";
import { AppDataContext } from "context/AppDataProvider";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { dateToString, localDateFromSQL } from "helpers/dateUtilities";
import classNames from "classnames";
import { XIcon } from "assets/icons";
import Widget from "components/Dashboard/Widget";
import InfiniteScroll from "react-infinite-scroll-component";
import { AUDIT_STATUS, LOADED_STATUS } from "constants.js";
import DescriptionListSkeleton from "components/core/Lists/DescriptionListSkeleton";
import { redirectToFormView } from "helpers/redirectUtilities";
import Breadcrumb from "components/Breadcrumb";

const limit = 25;

const ListPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [queryStatus, setQueryStatus] = React.useState(AUDIT_STATUS.OPEN);
  const [queryFieldStaff, setQueryFieldStaff] = React.useState("");
  const [queryFarmCode, setQueryFarmCode] = React.useState("");
  const [queryAuditDateFrom, setQueryAuditDateFrom] = React.useState("");
  const [queryAuditDateTo, setQueryAuditDateTo] = React.useState("");
  const [keys, setKeys] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [statusListOptions, setStatusListOptions] = React.useState([]);
  const [fieldStaffListOptions, setFieldStaffListOptions] = React.useState([]);
  const [farmListOptions, setFarmListOptions] = React.useState([]);
  const [loadedStatus, setLoadedStatus] = React.useState(undefined);
  const [hasMoreRecords, setHasMoreRecords] = useState(true);

  const abortControllerRef = useRef(undefined);
  const currentPageRef = useRef(1);
  const orderByRef = useRef("");
  const orderByDirectionRef = useRef("");

  const contentTypeMap = new Map([
    // ["due", "date"],
    ["audit date", "date"],
    ["severity", "status"],
    ["audit score", "number"],
    ["max score", "number"],
  ]);

  const { pageTitle, setPageTitle, pageSubtitle, setPageSubtitle, farms } =
    useContext(AppDataContext);

  const fetchFilters = async () => {
    const { signal } = abortControllerRef.current;

    try {
      const response = await fetch(
        `/api/overviewfilters-get?formId=nonconformance`,
        {
          signal,
          method: "GET",
        }
      );
      if (signal.aborted) return;

      if (response.ok) {
        const json = await response.json();

        const _statusListOptions =
          Object.entries(AUDIT_STATUS).map(([key, value]) => {
            return {
              Id: value,
              Text: key,
              Value: value,
            };
          }) ?? [];
        setStatusListOptions(_statusListOptions);

        const _fieldStaffListOptions =
          json
            ?.filter((lo) => lo.ListName?.toLowerCase() === "fieldstaffregion")
            ?.map((lo) => ({
              Id: lo.Id,
              Text: lo.Text,
              Value: lo.Id,
            })) ?? [];
        setFieldStaffListOptions(_fieldStaffListOptions);
      } else {
        console.error("error");
      }
    } catch (error) {
      if (signal.aborted) return;
      console.error(error);
    }
  };

  const loadMoreData = async () => {
    // Prevent loading more records before previous request has finished
    if (loadedStatus?.data === LOADED_STATUS.LOADING) return;

    currentPageRef.current++;
    fetchData(true);
  };

  const fetchData = async (isLoadMore = false) => {
    if (!isLoadMore) {
      setLoadedStatus((prevState) => ({
        ...prevState,
        data: LOADED_STATUS.LOADING,
      }));
    }

    const { signal } = abortControllerRef.current;

    const offset = (currentPageRef.current - 1) * limit;

    try {
      const response = await fetch(
        `/api/nonconformances-get?farmId=${queryFarmCode}&fromDate=${queryAuditDateFrom}&toDate=${queryAuditDateTo}&status=${queryStatus}&fieldStaff=${queryFieldStaff}&limit=${limit}&offset=${offset}&order=${orderByRef.current}&orderDirection=${orderByDirectionRef.current}`,
        {
          signal,
          method: "GET",
        }
      );

      if (signal.aborted) return;

      if (response.ok) {
        const json = await response.json();

        json.forEach((item) => {
          if (item.Due) {
            const newDueDate = !isNullEmptyOrWhitespace(item.Due)
              ? localDateFromSQL(item.Due)
              : item.Due;
            if (newDueDate instanceof Date) {
              if (newDueDate.getTime() < new Date().getTime()) {
                item.Due = (
                  <div className="text-danger-600">
                    {dateToString(newDueDate)}
                  </div>
                );
              } else {
                item.Due = <div>{dateToString(newDueDate)}</div>;
              }
            }
          }

          if (item.NCNID) {
            item.id = item.NCNID;
            delete item.NCNID;
          }

          const ncnFormName = item.NCNFormName?.toLowerCase();
          const auditPWAID = item.AuditPWAID?.toString();
          const auditFormName = item.AuditFormName?.toLowerCase();
          const recordId = item.id?.toString();

          if (ncnFormName && auditPWAID && auditFormName && recordId) {
            item.onClick = () => {
              return navigate(
                redirectToFormView(
                  location,
                  ncnFormName,
                  auditPWAID,
                  auditFormName,
                  recordId,
                  "audit", //TODO: remove hardcoded audit
                  item["Farm Code"],
                  "1" //TODO: remove hardcoded 1
                )
              );
            };
            delete item.NCNFormName;
            delete item.AuditPWAID;
            delete item.AuditFormName;
          }

          // Set key to prevent duplicates
          item.key = `${item.id}-${item.ParentQuestionID}`;
        });

        setHasMoreRecords(json.length < limit ? false : true);

        const newData = json ?? [];
        if (isLoadMore) {
          setData((prevState) => [...prevState, ...newData]);
        } else {
          setData(newData);
          const _firstRecord = json[0] ?? {};
          const _disallowedKeys = [
            "id",
            "key",
            "onclick",
            "ncnstatus",
            "parentquestionid",
            "ncnseverity",
            "ncnpwaid",
            "auditstatus",
          ];
          const _keys = Object.entries(_firstRecord).filter(
            ([key]) => !_disallowedKeys.includes(key.toLowerCase())
          );
          const _uniqueKeys = [
            ...new Set(
              _keys.map(([key, value]) => ({
                id: key,
                title: key,
                type: contentTypeMap.get(key?.toLowerCase()) ?? "string",
              }))
            ),
          ];
          setKeys(_uniqueKeys);
        }

        setLoadedStatus((prevState) => ({
          ...prevState,
          data: LOADED_STATUS.LOADED,
        }));
      } else {
        console.error("error");
        setLoadedStatus((prevState) => ({
          ...prevState,
          data: LOADED_STATUS.ERROR,
        }));
      }
    } catch (error) {
      if (signal.aborted) return;
      console.error(error);

      setLoadedStatus((prevState) => ({
        ...prevState,
        data: LOADED_STATUS.ERROR,
      }));
    }
  };

  useEffect(
    () => {
      abortControllerRef.current = new AbortController();
      fetchFilters();
      fetchData(false);

      return () => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        abortControllerRef.current.abort();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    setPageTitle("Non-Conformances");
    setPageSubtitle("");
  }, [setPageTitle, setPageSubtitle]);

  useEffect(() => {
    const _farmListOptions = farms.map((f) => ({
      Id: f.FarmCode,
      Text: `${f.FarmCode} - ${f.FarmName}`,
      Value: f.FarmCode,
    }));
    setFarmListOptions(_farmListOptions);
  }, [farms]);

  const handleSubmit = (e) => {
    e.preventDefault();

    currentPageRef.current = 1;
    setHasMoreRecords(true);
    fetchData(false);
  };

  const handleSortTable = (key, direction) => {
    orderByRef.current = key;
    orderByDirectionRef.current = direction;
    currentPageRef.current = 1;
    setHasMoreRecords(true);

    fetchData(false);
  };

  return (
    <div className="flex flex-col flex-grow min-h-full">
      <div className="relative z-20">
        <Breadcrumb
          key="breadcrumb"
          showHome={false}
          farmRequired={false}
          houseRequired={false}
        />
        <PageHeader
          key="pageHeader"
          title={pageTitle}
          subtitle={pageSubtitle}
          className="py-6 px-4 sm:px-6 lg:px-8"
        />
      </div>
      <main className="flex flex-grow flex-col">
        <div className="p-6 space-y-4">
          <Widget>
            <Filters
              className="p-4"
              queryStatus={queryStatus}
              setQueryStatus={setQueryStatus}
              queryFieldStaff={queryFieldStaff}
              setQueryFieldStaff={setQueryFieldStaff}
              queryFarmCode={queryFarmCode}
              setQueryFarmCode={setQueryFarmCode}
              queryAuditDateFrom={queryAuditDateFrom}
              setQueryAuditDateFrom={setQueryAuditDateFrom}
              queryAuditDateTo={queryAuditDateTo}
              setQueryAuditDateTo={setQueryAuditDateTo}
              statusListOptions={statusListOptions}
              fieldStaffListOptions={fieldStaffListOptions}
              farmListOptions={farmListOptions}
              onSubmit={handleSubmit}
            />
          </Widget>
          {loadedStatus?.data === LOADED_STATUS.LOADED ? (
            <InfiniteScroll
              className="infinite-scroll"
              style={{ overflow: "visible" }}
              dataLength={data.length}
              next={loadMoreData}
              hasMore={hasMoreRecords}
              loader={<DescriptionListSkeleton />}
              endMessage={<p className="text-center text-gray-700 py-4">End of records</p>}
            >
              <TableChart
                keys={keys}
                data={data}
                title="Non Conformance Overview"
                settings={{
                  showConfig: false,
                  showPagination: false,
                }}
                onSortBy={handleSortTable}
                sortBy={{
                  key: orderByRef.current,
                  direction: orderByDirectionRef.current,
                }}
              />
            </InfiniteScroll>
          ) : loadedStatus?.data === LOADED_STATUS.ERROR ? (
            <Alert theme="danger">
              An error occured while loading the records. Please refresh and try
              again. If the issue continues please contact the technical support
              team.
            </Alert>
          ) : (
            <DescriptionListSkeleton />
          )}
        </div>
      </main>
    </div>
  );
};

function Filters({
  queryStatus,
  setQueryStatus,
  queryFieldStaff,
  setQueryFieldStaff,
  queryFarmCode,
  setQueryFarmCode,
  queryAuditDateFrom,
  setQueryAuditDateFrom,
  queryAuditDateTo,
  setQueryAuditDateTo,
  statusListOptions,
  fieldStaffListOptions,
  farmListOptions,
  className,
  onSubmit,
  ...other
}) {
  return (
    <div
      className={classNames(
        "grid grid-cols-2 tablet:grid-cols-3 gap-4",
        className
      )}
      {...other}
    >
      <div>
        <MultiSelect
          id="filter-farms"
          label="Farm(s)"
          labelPosition="inset"
          listOptions={farmListOptions}
          value={queryFarmCode}
          setValue={setQueryFarmCode}
          required={false}
          placeholder="Search by Farm Code"
          showSearch={true}
          disableCalcTrigger={true}
        />
      </div>
      <div className="relative">
        <Datepicker
          id="filter-audit-date-from"
          label="Audit Date From"
          labelPosition="inset"
          placeholder="Search by Audit Date from"
          value={queryAuditDateFrom}
          setValue={setQueryAuditDateFrom}
          required={false}
          disableCalcTrigger={true}
        />
        {!isNullEmptyOrWhitespace(queryAuditDateFrom) && (
          <div
            className="absolute right-10 bottom-4 cursor-pointer group"
            onClick={() => setQueryAuditDateFrom("")}
          >
            <XIcon
              className="h-5 w-5 text-gray-400 group-hover:text-primary"
              title="clear value"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      <div className="relative">
        <Datepicker
          id="filter-audit-date-to"
          label="Audit Date To"
          labelPosition="inset"
          placeholder="Search by Audit Date to"
          value={queryAuditDateTo}
          setValue={setQueryAuditDateTo}
          required={false}
          disableCalcTrigger={true}
        />
        {!isNullEmptyOrWhitespace(queryAuditDateTo) && (
          <div
            className="absolute right-10 bottom-3.5 cursor-pointer group"
            onClick={() => setQueryAuditDateTo("")}
          >
            <XIcon
              className="h-5 w-5 text-gray-400 group-hover:text-primary"
              title="clear value"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      <div>
        <MultiSelect
          id="filter-status"
          label="Status"
          labelPosition="inset"
          listOptions={statusListOptions}
          value={queryStatus ?? statusListOptions[0].Value}
          setValue={setQueryStatus}
          required={false}
          disableCalcTrigger={true}
        />
      </div>
      <div>
        <MultiSelect
          id="filter-field-staff"
          label="Field Staff"
          labelPosition="inset"
          listOptions={fieldStaffListOptions}
          value={queryFieldStaff}
          setValue={setQueryFieldStaff}
          required={false}
          showSearch={true}
          disableCalcTrigger={true}
        />
      </div>
      <Button onClick={onSubmit} theme="primary">
        Submit
      </Button>
    </div>
  );
}

export default ListPage;
