import { BreadcrumbItem } from "components/core/Breadcrumbs/Breadcrumb";
import { redirectToListView } from "helpers/redirectUtilities";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import useQuery from "hooks/useQuery";
import { useNavigate } from "react-router-dom";

export default function BreadcrumbItemMenu({ menu, farm, menus, ...other }) {
  const navigate = useNavigate();
  const query = useQuery();
  const menuId = query.get("menuId");

  const menuFilteredByMeta = () => {
    let result = {};

    if (!isNullEmptyOrWhitespace(farm?.FarmGroup)) {
      result["Group"] = [farm.FarmGroup];
    }

    return result;
  };

  //#region Event handlers

  const handleClickMenu = (newMenuId) => {
    const prevMenuId = menuId;
    navigate(redirectToListView(window.location, newMenuId));

    if (newMenuId.toLowerCase() === prevMenuId?.toLowerCase()) {
      // Same page, force reload to refetch data
      window.location.reload();
    }
  };

  //#endregion

  return (
    <BreadcrumbItem
      {...other}
      title={menu?.MenuName}
      subtitle="Menu"
      showDivider={false}
      loaded={menus.length > 0}
      options={menus.map((m) => ({
        id: m.MenuOption,
        text: <div className="font-medium text-primary mr-3">{m.MenuName}</div>,
        onClick: () => handleClickMenu(m.MenuOption),
        meta:
          m.Metadata?.Display?.FarmGroups !== undefined
            ? { Group: m.Metadata.Display.FarmGroups }
            : null,
      }))}
      optionsHeading={<div className="text-lg font-medium">Menu</div>}
      optionsFilteredByMeta={menuFilteredByMeta()}
    />
  );
}
