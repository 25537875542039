import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ButtonGroup as CoreButtonGroup } from "components/core";
import useDeepCompareEffect from "use-deep-compare-effect";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from "assets/icons";
import useCalcTrigger from "hooks/useCalcTrigger";

export function ButtonGroup({
  id,
  label,
  hint,
  value,
  setValue,
  children,
  validate,
  setValid,
  render,
  listOptions = [],
  disableCalcTrigger,
  ...other
}) {
  useCalcTrigger(value, setValue, disableCalcTrigger); // To act like the input onChange event

  const [listOptionsPlus, setListOptionsPlus] = useState(listOptions);
  const [error, setError] = useState(false);

  //#region Callbacks

  /**
   * Set the input value.
   * @param {string} value  The new input value.
   */
  const setInputValue = useCallback(
    (value) => {
      // Prevent setting input to null or undefined
      // Controlled components should not have null or undefined value
      if (typeof value === "undefined" || value === null) return;

      setValue(value);
    },
    [setValue]
  );

  /**
   * Validate the input value.
   * @returns {string|null} The error message or null if valid.
   */
  const validation = () => {
    if (validate) return validate(value);
  };

  //#endregion

  //#region Side-effects

  /**
   * Trigger validation when value or dependencies change.
   * Useful when you wish to revalidate when related input changes.
   */
  useEffect(() => {
    const validationResult = validation();
    if (validationResult !== error) setError(validationResult);
    if (setValid) setValid(!validationResult, value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  /**
   * Watch for changes to listOptions
   */
  useDeepCompareEffect(() => {
    if (!render) return;

    const getAdditionalProps = (option) => {
      let props = {};

      // Button
      if (option?.SeverityColour !== undefined) {
        if (option.SeverityColour === 0) {
          props.activeTheme = "gray";
        } else if (option.SeverityColour === 1) {
          props.activeTheme = "success";
          props.icon = <CheckCircleIcon className="w-4 h-4 mr-1" />;
        } else if (option.SeverityColour === 2) {
          props.activeTheme = "warning";
          props.icon = <InformationCircleIcon className="w-4 h-4 mr-1" />;
        } else if (option.SeverityColour === 3) {
          props.activeTheme = "danger";
          props.icon = <ExclamationCircleIcon className="w-4 h-4 mr-1" />;
        } else if (option.SeverityColour === 4) {
          props.activeTheme = "secondary";
          props.icon = <XCircleIcon className="w-4 h-4 mr-1" />;
        } else {
          props.theme = undefined;
        }
      }

      return props;
    };

    const _newListOptions = listOptions.map((option) => {
      const _additionalProps = getAdditionalProps(option);
      return { ..._additionalProps, ...option };
    });
    setListOptionsPlus(_newListOptions);
  }, [listOptions, render]);

  //#endregion

  // Prevent dom element rendering
  if (render === false) {
    return null;
  }

  return (
    <div>
      <CoreButtonGroup
        {...other}
        id={id}
        label={label}
        labelPosition="left"
        listOptions={listOptionsPlus}
        onClick={setInputValue}
        active={value}
      />
      {hint && <p className="mt-2 text-xs text-gray-500">{hint}</p>}
    </div>
  );
}

ButtonGroup.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setValue: PropTypes.func,
  children: PropTypes.any,
  validate: PropTypes.func,
  setValid: PropTypes.func,
  render: PropTypes.bool,
};
