import { useState, useEffect, useContext, useCallback, useRef } from "react";
import PageHeader from "components/PageHeader";
import Breadcrumb from "components/Breadcrumb";
import { CHART_TYPE, DASHBOARD_DATASOURCES, LOADED_STATUS } from "constants.js";
import { Button, Input, MultiSelect } from "components/core";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { dateToISO } from "helpers/dateUtilities";
import useQuery from "hooks/useQuery";
import { AppDataContext } from "context/AppDataProvider";
import { NotificationContext } from "context/NotificationProvider";
import Widget from "components/Dashboard/Builder/Widget";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { v4 as uuid } from "uuid";
import {
  extractMetricsFromJsonObjs,
  formatChartAttrs,
  formatChartData,
} from "helpers/dashboardUtilities";
import { redirectToListView } from "helpers/redirectUtilities";
import useDeepCompareEffect from "use-deep-compare-effect";
import AddChart from "components/Dashboard/Builder/AddChart";
import { ReactSortable } from "react-sortablejs";

export default function Builder() {
  const query = useQuery();
  const farmId = query.get("farmId");
  const houseId = query.get("houseId");
  const menuId = query.get("menuId");
  const formId = query.get("formId");

  const { id: dashboardId } = useParams();

  const abortControllerRef = useRef(undefined);

  const navigate = useNavigate();
  const location = useLocation();

  const { addNotification } = useContext(NotificationContext);
  const { farms } = useContext(AppDataContext);

  //#region State

  const [reports, setReports] = useState([]);
  const [metrics, setMetrics] = useState([]);
  const [selectedCharts, setSelectedCharts] = useState([]);
  const [chartsData, setChartsData] = useState([]);
  const [chartTypes, setChartTypes] = useState(undefined);
  const [code, setCode] = useState("");
  const [farmGroups, setFarmGroups] = useState([]);
  const [loaded, setLoaded] = useState(LOADED_STATUS.LOADING);

  // Form properties
  const [selectedFarmGroups, setSelectedFarmGroups] = useState("");
  const [selectedDataSources, setSelectedDataSources] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("New dashboard");

  //#endregion

  //#region Callbacks

  const fetchCharts = useCallback(async () => {
    if (!farmId || !houseId || isNullEmptyOrWhitespace(farms)) return [];

    // Clear previous values
    setSelectedCharts([]);
    setLoaded(LOADED_STATUS.LOADING);

    // Charts
    const _dashboards = await fetchDashboardsWithCharts();
    // At present, only use a single dashboard
    if (_dashboards.length > 0) {
      const _dashboard = _dashboards[0]; // Get first
      const _charts = _dashboard?.charts ?? [];

      // Build array of charts and chart data
      for (const chart of _charts) {
        // Add chart
        addChart(chart);
      }

      // Set form defaults
      setSelectedDataSources(_dashboard.dataSources);
      setSelectedFarmGroups(_dashboard.farmGroups);
      setSelectedTitle(_dashboard.title);
    }

    setLoaded(LOADED_STATUS.LOADED);

    /**
     * Fetch charts
     */
    async function fetchDashboardsWithCharts() {
      const { signal } = abortControllerRef.current;
      try {
        const response = await fetch(`/api/dashboard-get?id=${dashboardId}`, {
          signal,
          method: "GET",
        });
        if (response.ok) {
          const responseData = await response.json();
          setLoaded(LOADED_STATUS.LOADED);

          return responseData ?? [];
        } else {
          setLoaded(LOADED_STATUS.ERROR);

          addNotification({
            title: "Error",
            theme: "error",
            description: "An error occurred while fetching dashboard.",
          });
        }
      } catch (err) {
        if (signal.aborted) return [];
        console.error(err);

        setLoaded(LOADED_STATUS.ERROR);

        addNotification({
          title: "Error",
          theme: "error",
          description: "An error occurred while fetching dashboard.",
        });
      }

      return [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farmId, farms, houseId, dashboardId]);

  const fetchFarmGroups = useCallback(async () => {
    const { signal } = abortControllerRef.current;
    /**
     * Fetch farm groups
     */
    try {
      const response = await fetch(`/api/farmgroups-get`, {
        signal,
        method: "GET",
      });
      if (response.ok) {
        const responseData = await response.json();

        setFarmGroups(responseData);
        return responseData;
      } else {
        setLoaded(LOADED_STATUS.ERROR);

        addNotification({
          title: "Error",
          theme: "error",
          description: "An error occurred while fetching farm groups.",
        });
      }
    } catch (err) {
      if (signal.aborted) return;
      console.error(err);

      setLoaded(LOADED_STATUS.ERROR);

      addNotification({
        title: "Error",
        theme: "error",
        description: "An error occurred while fetching farm groups.",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchChartTypes = () => {
    setChartTypes(
      Object.entries(CHART_TYPE).map(([key, value]) => {
        return {
          id: key.toLowerCase(),
          title: value,
        };
      })
    );
  };

  const addChart = (newChart) => {
    // Add new chart to the list
    setSelectedCharts((prevState) => {
      const newState = [...prevState];
      newState.unshift(newChart);

      // Sort by position, desc
      newState.sort((a, b) => a.position - b.position);

      return newState;
    });
  };

  const addChartData = (chart, reports) => {
    // Build data
    setChartsData((prevState) => {
      const newState = prevState.filter((cd) => cd.id !== chart.id);
      const _chartData = formatChartData(reports, chart);
      newState.push({ id: chart.id, data: _chartData });

      return newState;
    });
  };

  //#endregion

  //#region Side-effects

  useEffect(() => {
    abortControllerRef.current = new AbortController();

    return () => {
      abortControllerRef.current.abort();
    };
  }, []);

  /**
   * Fetch data
   */
  useEffect(() => {
    fetchCharts();
    fetchChartTypes();
    fetchFarmGroups();
  }, [fetchCharts, fetchFarmGroups]);

  /**
   * Fetch data
   */
  useEffect(() => {
    if (
      !farmId ||
      !houseId ||
      isNullEmptyOrWhitespace(farms) ||
      isNullEmptyOrWhitespace(selectedDataSources)
    )
      return;

    const fetchData = async () => {
      // Clear previous values
      setReports([]);
      setMetrics([]);
      setLoaded(LOADED_STATUS.LOADING);

      // Fetch dashboard data
      const _dataSources = selectedDataSources.split(",");
      const _reports = await Promise.all(
        _dataSources.map((dataSource) => fetchData(dataSource))
      );
      const _flattenedReports = _reports.flat()?.filter(report => report !== undefined);
      // Merge objects by "age" property
      // const mergedData = mergeObjectsByProperty(_reports, "age");
      setReports(_flattenedReports);

      // Charts
      // Add data to charts
      for (const chart of selectedCharts) {
        // Add chart data
        addChartData(chart, _flattenedReports);
      }

      setLoaded(LOADED_STATUS.LOADED);

      /**
       * Fetch data
       */
      async function fetchData(dataSources) {
        const { signal } = abortControllerRef.current;
        // DashboardChartsWebService

        // const dates = getLastXDays(60); // month
        // const fromDate = dateToISO(dates[dates.length - 1].getTime());
        // const toDate = dateToISO(dates[0].getTime());
        const farm = farms.find(
          (farm) => farm.FarmCode.toLowerCase() === farmId.toLowerCase()
        );
        const house = farm.Houses.find(
          (h) => h.HouseNumber.toString() === houseId.toString()
        );
        const cropDate = house?.Pens?.find(
          (p) => p.PenNumber.toString() === "1"
        ).Placement?._CropDate?.normalised;
        const cropDateFormatted = dateToISO(cropDate?.getTime());

        try {
          const response = await fetch(
            // `/api/chartdata-get?farmId=${farmId}&toDate=${toDate}&fromDate=${fromDate}`,
            `/api/chartdata-get?farmId=${farmId}&cropDate=${cropDateFormatted}&house=${houseId}&dataSource=${dataSources}`,
            {
              signal,
              method: "GET",
            }
          );
          if (response.ok) {
            const responseData = await response.json();

            return responseData;
          } else {
            setLoaded(LOADED_STATUS.ERROR);

            addNotification({
              title: "Error",
              theme: "error",
              description: "An error occurred while fetching dashboard data.",
            });
          }
        } catch (err) {
          if (signal.aborted) return;
          console.error(err);

          setLoaded(LOADED_STATUS.ERROR);

          addNotification({
            title: "Error",
            theme: "error",
            description: "An error occurred while fetching dashboard data.",
          });
        }
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farmId, farms, houseId, selectedDataSources]);

  /**
   * Set metrics
   */
  useDeepCompareEffect(() => {
    if (isNullEmptyOrWhitespace(reports)) return;

    const _metrics = extractMetricsFromJsonObjs(reports);
    setMetrics(_metrics);
  }, [reports]);

  /**
   * Generate export code on-the-fly
   */
  useDeepCompareEffect(() => {
    function generateJsonCode() {
      const newCode = JSON.stringify(selectedCharts);
      setCode(newCode);
    }

    generateJsonCode();
  }, [selectedCharts]);

  //#endregion

  //#region handlers

  const handleSaveDashboard = async () => {
    const { signal } = abortControllerRef.current;
    try {
      const response = await fetch(`/api/dashboard-post`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: isNullEmptyOrWhitespace(dashboardId) ? uuid() : dashboardId,
          title: selectedTitle,
          farmId,
          houseId,
          farmGroups: selectedFarmGroups,
          charts: code,
          dataSources: selectedDataSources,
        }),
      });

      if (response.ok) {
        addNotification({
          title: "Saved",
          theme: "success",
          description: "Your dashboard has been saved.",
        });

        setLoaded(LOADED_STATUS.LOADED);

        return navigate(redirectToListView(location, menuId, formId));
      } else {
        setLoaded(LOADED_STATUS.ERROR);
        addNotification({
          title: "Error",
          theme: "error",
          description: "An error occurred while saving your dashboard.",
        });
      }
    } catch (err) {
      if (signal.aborted) return;
      console.error(err);

      setLoaded(LOADED_STATUS.ERROR);

      addNotification({
        title: "Error",
        theme: "error",
        description: "An error occurred while saving your dashboard.",
      });
    }
  };

  const handleDeleteDashboard = async () => {
    const { signal } = abortControllerRef.current;
    try {
      await fetch(`/api/dashboard-delete`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: dashboardId,
        }),
      });

      addNotification({
        title: "Deleted",
        theme: "success",
        description: "Your dashboard has been deleted.",
      });

      setLoaded(LOADED_STATUS.LOADED);

      return navigate(redirectToListView(location, menuId, formId));
    } catch (err) {
      if (signal.aborted) return;
      console.error(err);

      setLoaded(LOADED_STATUS.ERROR);
    }
  };

  const handleChangeTitle = (value) => {
    setSelectedTitle(value);
  };

  const handleClickCancel = () => {
    return navigate(redirectToListView(location, menuId, formId));
  };

  const handleDeleteChart = async (chartId) => {
    try {
      setSelectedCharts(selectedCharts.filter((sc) => sc.id !== chartId));
      addNotification({
        title: "Deleted",
        theme: null,
        description: "Chart deleted.",
      });
    } catch (err) {
      console.error(err);

      setLoaded(LOADED_STATUS.ERROR);
    }
  };

  const handleChangeChartTitle = (chartId, value) => {
    const newSelectedCharts = [...selectedCharts];
    const chartIndex = newSelectedCharts.findIndex((x) => x.id === chartId);
    const chart = { ...newSelectedCharts[chartIndex] };

    // Update title
    chart.title = value;

    // Insert new chart in same array location
    newSelectedCharts.splice(chartIndex, 1, chart);

    setSelectedCharts(newSelectedCharts);
  };

  const handleChangeAttr = (chartId, name, value) => {
    const newSelectedCharts = [...selectedCharts];
    const chartIndex = newSelectedCharts.findIndex((x) => x.id === chartId);
    const chart = { ...newSelectedCharts[chartIndex] };

    chart.attrs = {
      ...chart.attrs,
      [name]: value,
    };

    // Insert new chart in same array location
    newSelectedCharts.splice(chartIndex, 1, chart);

    setSelectedCharts(newSelectedCharts);
  };

  const handleChangeSetting = (chartId, name, value) => {
    const newSelectedCharts = [...selectedCharts];
    const chartIndex = selectedCharts.findIndex((x) => x.id === chartId);
    const chart = selectedCharts[chartIndex];

    const newChart = {
      ...chart,
      settings: {
        ...chart.settings,
        [name]: value,
      },
    };

    // Insert new chart in same array location
    newSelectedCharts.splice(chartIndex, 1, newChart);

    setSelectedCharts(newSelectedCharts);
  };

  const handleChangeFarmGroups = (values) => {
    setSelectedFarmGroups(values);
  };

  const handleChangeDataSources = (values) => {
    setSelectedDataSources(values);
  };

  const handleAddChart = (chartType) => {
    if (!chartType) throw new Error("Chart type is required");

    // Setup chart defaults
    const newChart = {
      id: uuid(),
      title: undefined,
      type: chartType,
      keys: [],
      attrs: {},
      settings: {
        colors: ["#df386f", "#01295F", "#437F97", "#849324", "#FFB30F"],
      },
      position: selectedCharts.length,
    };

    addChart(newChart);
  };

  const handleAddMetrics = (chartId, metricIds) => {
    if (!metricIds) throw new Error("Metric ID is required");

    const newSelectedCharts = [...selectedCharts];
    const chartIndex = selectedCharts.findIndex((x) => x.id === chartId);
    const chart = selectedCharts[chartIndex];

    const newChart = {
      ...chart,
      keys: [], // Clear existing keys
    };

    for (const metricId of metricIds) {
      // Get metric from metric ID
      const metric = metrics.find((x) => x.id === metricId);
      if (!metric) throw new Error(`${metricId} metric not found`);
      // Add metric to the list
      newChart.keys.push(metric);
    }

    // Set widget title if not set
    if (!newChart.title) {
      newChart.title = `${newChart.keys[0].title} Chart`;
    }

    // Update chart attrs
    const _chartAttrs = formatChartAttrs(newChart);
    newChart.attrs = _chartAttrs;

    // Insert new chart in same array location
    newSelectedCharts.splice(chartIndex, 1, newChart);

    setSelectedCharts(newSelectedCharts);

    // Add chart data
    addChartData(newChart, reports);
  };

  const handleAddColors = (chartId, colorIds) => {
    if (!colorIds) throw new Error("Color ID is required");

    handleChangeSetting(chartId, "colors", colorIds);
  };

  function handleChangePosition() {
    // Set position property for each chart from index
    const _charts = selectedCharts.map((chart, index) => {
      return { ...chart, position: index };
    });

    setSelectedCharts(_charts);
  }

  //#endregion

  let parsedCode = [];
  if (!!parsedCode) {
    try {
      parsedCode = JSON.parse(code);
    } catch {
      // Do nothing
    }
  }

  return (
    <div className="flex-grow overflow-x-hidden">
      <div className="relative z-20">
        <Breadcrumb
          showHome={false}
          menuRequired={true}
          houseRequired={true}
          farmRequired={true}
        />
        <PageHeader
          className="py-6 px-4 sm:px-6 lg:px-8"
          title="Chart Builder"
        ></PageHeader>
      </div>
      <main className="flex flex-grow flex-col">
        <div className="p-4">
          {loaded === LOADED_STATUS.LOADED ? (
            <>
              {!isNullEmptyOrWhitespace(selectedDataSources) ? (
                <>
                  <AddChart
                    chartTypes={chartTypes}
                    className="col-span-12 mb-6 h-12"
                    onAddChart={handleAddChart}
                  />
                  <ReactSortable
                    list={selectedCharts}
                    setList={setSelectedCharts}
                    className="grid gap-4 grid-cols-12"
                    tag="div"
                    handle=".dragHandle"
                    onEnd={handleChangePosition}
                  >
                    {selectedCharts.map((chart) => {
                      const chartData =
                        chartsData?.find((cd) => cd.id === chart.id)?.data ??
                        [];
                      return (
                        <Widget
                          key={`widget_${chart.id}`}
                          chart={chart}
                          data={chartData}
                          onDelete={handleDeleteChart}
                          onChangeTitle={handleChangeChartTitle}
                          onChangeAttr={handleChangeAttr}
                          onChangeSetting={handleChangeSetting}
                          metrics={metrics}
                          onAddMetrics={handleAddMetrics}
                          onAddColors={handleAddColors}
                        />
                      );
                    })}
                  </ReactSortable>
                </>
              ) : (
                <div className="text-center text-warning-500">
                  Select a data source to continue.
                </div>
              )}
              <div className="space-y-4 mt-4">
                <Input
                  id="title"
                  label="Dashboard Title"
                  type="text"
                  value={selectedTitle}
                  labelPosition="top"
                  setValue={handleChangeTitle}
                  disableCalcTrigger={true}
                />
                <MultiSelect
                  label="Data Source"
                  id="datasource-multiselect"
                  setValue={(ev) => handleChangeDataSources(ev)}
                  value={selectedDataSources}
                  listOptions={Object.values(DASHBOARD_DATASOURCES).map(
                    (dataSource) => ({
                      Id: dataSource,
                      Text: dataSource,
                      Value: dataSource,
                    })
                  )}
                  disableCalcTrigger={true}
                />
                {farmGroups.length > 0 && (
                  <MultiSelect
                    label="Farm group(s)"
                    id="farmgroups-multiselect"
                    setValue={(ev) => handleChangeFarmGroups(ev)}
                    value={selectedFarmGroups}
                    listOptions={farmGroups.map((fg) => ({
                      Id: fg,
                      Text: fg,
                      Value: fg,
                    }))}
                    disableCalcTrigger={true}
                  />
                )}
                <div className="tablet:flex w-full">
                  <div className="inline-flex space-x-2 justify-end order-2 flex-grow">
                    <Button className="mt-4" onClick={handleClickCancel}>
                      Cancel
                    </Button>
                    <Button
                      theme="primary"
                      className="mt-4"
                      onClick={handleSaveDashboard}
                    >
                      Save dashboard
                    </Button>
                  </div>
                  {!isNullEmptyOrWhitespace(dashboardId) && (
                    <div className="justify-start order-1">
                      <Button
                        theme="danger"
                        className="mt-4"
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you wish to delete the dashboard?"
                            )
                          )
                            handleDeleteDashboard();
                        }}
                      >
                        Delete dashboard
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : (
            <div className="space-y-4">
              <div className="animate-pulse w-full h-56 rounded-md bg-gray-300" />
            </div>
          )}
        </div>
      </main>
    </div>
  );
}
