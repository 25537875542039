import React from "react";
import PropTypes from "prop-types";
import { CheckCircleIcon } from "assets/icons";

const Notification = ({ title, description, icon, theme, onClose, className = "", ...other }) => {
  const classes = [];

  classes.push("w-8");

  if (theme === "success") {
    classes.push("text-success-400");
  } else if (theme === "warning") {
    classes.push("text-warning-300");
  } else if (theme === "error") {
    classes.push("text-danger-400");
  } else {
    classes.push("text-gray-400");
  }

  const Icon = icon ? icon : CheckCircleIcon;

  return (
    <div className="fixed inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end z-50">
      <div {...other} className={`max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden ${className}`}>
        <div className="p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              <Icon className={classes.join(" ")} />
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className="text-sm font-medium text-gray-900">{title}</p>
              <p className="mt-1 text-sm text-gray-500">{description}</p>
            </div>
            <div className="ml-4 flex-shrink-0 flex">
              <button
                onClick={onClose}
                className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <span className="sr-only">Close</span>
                {/*Heroicon name: solid/x */}
                <svg
                  className="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Notification.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.node,
  description: PropTypes.any,
  theme: PropTypes.oneOf(["success", "warning", "error"]),
};

export default Notification;