import React, { useState, useContext } from "react";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import { Button } from "components/core";
import { LockClosedIcon } from "assets/icons";
import { NotificationContext } from "context/NotificationProvider";
import { Input } from "components/core";
import Logo from "assets/Logo";
import { AppDataContext } from "context/AppDataProvider";

export default function Login() {
  let navigate = useNavigate();
  let location = useLocation();
  let auth = useAuth();
  let { from } = location.state || { from: { pathname: "/" } };

  const { addNotification } = useContext(NotificationContext);
  const { isSignedIn } = useContext(AppDataContext);

  //#region state

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  // Validation states
  const [usernameValid, setUsernameValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);

  //#endregion

  //#region event listeners

  const handleLogin = (ev) => {
    ev.preventDefault();

    if (!formValid()) return; // Prevent invalid submission
    
    auth.signin(username, password, (res) => {
      if (res.message === "Success") {
        addNotification({
          key: "login-success-notification",
          title: "Login success",
          theme: "success",
          description: "You have successfully logged in.",
        });
        navigate(from, { replace: true });
      } else {
        addNotification({
          key: "login-failed-notification",
          title: "Login failed",
          theme: "error",
          description: "Check your user credentials and try again. If the problem persists please contact support.",
        });
      }
    });
  };

  //#endregion

  //#region validation

  const formValid = () => {
    if (
      !usernameValid ||
      !passwordValid
    ) {
      return false;
    }

    return true;
  };

  const validUsername = (value) => {
    return;
  };

  const validPassword = (value) => {
    return;
  };

  //#endregion

  if (isSignedIn) {
    return <Navigate to="/" />;
  }

  return (
    <div className="min-h-screen flex flex-1 items-center text-center bg-gray-900">
      <div className="flex-1 flex justify-center py-12 px-6 tablet:px-6 desktop:px-20">
        <div className="mx-auto w-full max-w-sm desktop:w-96">
          <div>
            <Logo className="h-24 w-auto mx-auto text-gray-500" />
            <h1 className="sr-only">
              Sign in to your account
            </h1>
          </div>

          <div className="mt-8">
            <div className="mt-6">
              <form onSubmit={handleLogin} className="space-y-6">
                <Input
                  id="username"
                  label="Username"
                  type="text"
                  value={username}
                  labelPosition="inset"
                  theme="darkgray"
                  setValue={setUsername}
                  setValid={setUsernameValid}
                  validate={validUsername}
                  disableCalcTrigger={true}
                />

                <Input
                  id="password"
                  label="Password"
                  type="password"
                  value={password}
                  labelPosition="inset"
                  theme="darkgray"
                  setValue={setPassword}
                  setValid={setPasswordValid}
                  validate={validPassword}
                  disableCalcTrigger={true}
                />

                <Button
                  type="submit"
                  theme="primary"
                  size="large"
                  isFullWidth
                  className="relative"
                  disabled={!formValid()}
                >
                  <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                    <LockClosedIcon className="h-5 w-5 text-primary-light group-hover:text-primary-light" />
                  </span>
                  Sign in
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
