import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ListItem } from "components/core";
import ListItemIcon from "components/Schedule/ListItemIcon";
import ListItemStatus from "components/Schedule/ListItemStatus";
import { dateToString } from "helpers/dateUtilities";

export default function ListItemDaily({
  todayEntries,
  date,
  dateToday,
  farmId,
  houseId,
  formId,
  loaded = true,
  className,
  onClick,
  ...other
}) {
  let navigate = useNavigate();

  const [entry, setEntry] = useState(undefined);
  const [classes, setClasses] = useState([className]);

  const abortControllerRef = useRef(undefined);

  //#region Callbacks

  /**
   * Handle list item click
   */
  const handleClick = (timestamp, formId) => {
    const searchParams = new URLSearchParams(
      window.location.search.substring(1)
    );
    searchParams.set("formId", formId);
    navigate({
      pathname: `/forms/edit/${timestamp}`,
      search: "?" + searchParams.toString(),
    });
  };

  //#endregion

  //#region Side-effects

  /**
   * Mount/Unmount
   */
  useEffect(
    () => {
      abortControllerRef.current = new AbortController();

      return () => abortControllerRef.current.abort();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  /**
   * Build classes
   */
  useEffect(() => {
    setClasses([className, !loaded ? "animate-pulse" : ""]);
  }, [loaded, className]);

  /**
   * Set entry
   */
  useEffect(() => {
    if (!todayEntries) return;

    const _newEntry = todayEntries?.find(
      (e) => e.FormName.toLowerCase() === "production"
    );
    setEntry(_newEntry ?? null);
  }, [todayEntries]);

  //#endregion

  if (!loaded) {
    return (
      <ListItem className="animate-pulse" aria-label="daily">
        <div className="h-10 w-10 mr-2 rounded-full bg-gray-300"></div>
        <div className="flex-grow">
          <div className="mb-0.5 h-4 w-1/4 bg-gray-300 rounded"></div>
          <div className="text-xs text-gray-500">
            <div className="flex">
              <div className="h-2 w-1/2 bg-gray-300 rounded"></div>
            </div>
          </div>
        </div>
      </ListItem>
    );
  }

  return (
    <ListItem
      aria-label="daily"
      className={classes.join(" ")}
      onClick={() => handleClick(date.getTime(), "production")}
      {...other}
    >
      <ListItemIcon
        dataStatus={entry?.Status}
        date={date}
        dateToday={dateToday}
        loaded={entry !== undefined}
      />
      <div className="flex-grow">
        <div className="font-medium">Daily</div>
        <div className="flex text-sm">
          <ListItemStatus
            dataStatus={entry?.Status}
            date={date}
            dateToday={dateToday}
            loaded={entry !== undefined}
          />
        </div>
        {entry && entry._LastModified && (
          <div className="text-xs text-gray-400">
            Updated:{" "}
            {dateToString(entry._LastModified.localised, {
              includeTime: true,
            })}
          </div>
        )}
      </div>
    </ListItem>
  );
}
