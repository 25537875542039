import { ApplicationInsights, SeverityLevel } from "@microsoft/applicationinsights-web";

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_APP_INSIGHT_INSTRUMENTATION_KEY,
    sendLiveMetrics: true,
  },
});

// Measure performance and capture exceptions from user devices
appInsights.loadAppInsights();
appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview

export { appInsights as default, SeverityLevel };